import { Avatar, AvatarProps } from "@material-tailwind/react";
import { Ref } from "react";
import { Driver, Nullable, User } from "src/types";

interface Props {
    user?: Nullable<User> | Nullable<Driver> | Nullable<Custom>;
    ref?: Ref<HTMLImageElement>;
}

interface Custom {
    username: string;
    profile_photo_url: string;
}

export default function ProfilePhoto({
    user,
    ref,
    ...rest
}: Props & AvatarProps) {
    if (!user) {
        return null;
    }

    return (
        <Avatar
            src={user?.profile_photo_url}
            alt={user?.username}
            ref={ref}
            {...rest}
        />
    );
}
