import axios from "axios";
import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { json, LoaderFunctionArgs, Navigate, redirect } from "react-router-dom";
import { useSanctum } from "react-sanctum";

import { ApiUrl } from "../Helpers/API_URL";

export async function AuthenticatedLoader(
    { request }: LoaderFunctionArgs,
    bypassRestriction: boolean = false,
) {
    try {
        return await axios
            .get(ApiUrl("user"), { maxRedirects: 0 })
            .then((response) => {
                if (!bypassRestriction && response.data.restricted) {
                    return redirect(`/restricted`);
                }

                return null;
            });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response && error.response.status === 401) {
                // If there's a 401 error the user is not signed in.
                // Redirect them to the /login page, but save the current location they were
                // trying to go to when they were redirected. This allows us to send them
                // along to that page after they login, which is a nicer user experience
                // than dropping them off on the home page.
                let params = new URLSearchParams();
                params.set("from", new URL(request.url).pathname);
                return redirect(`/login?${params.toString()}`);
            }
        }
    }
}

export function Logout() {
    const { authenticated, signOut } = useSanctum();

    useEffect(() => {
        if (authenticated) {
            const doSignOut = async () => {
                try {
                    await signOut();
                } catch (e) {
                    // Do nothing
                }
            };
            doSignOut();
        }
    }, [authenticated, signOut]);

    return <Navigate to={"/"} />;
}

export async function AuthenticationLoader() {
    let data = await axios.get(ApiUrl("data/login")).then((response) => {
        return response.data;
    });
    return json(data);
}

export const PermissionContext = createContext<Array<string>>([]);

export const PermissionProvider = ({ children }: PropsWithChildren) => {
    const [perms, setPerms] = useState<Array<string>>([]);
    const { authenticated } = useSanctum();

    useEffect(() => {
        if (authenticated) {
            getPermissions();
        }
    }, []);

    const getPermissions = async () => {
        await axios
            .get(ApiUrl("user/permissions"))
            .then((response) => {
                setPerms(response.data.perms);
            })
            .catch((error) => {
                if (error.response.status && error.response.status !== 422) {
                    throw new Response(error.response.message || "", {
                        status: error.response.status,
                    });
                }
            });
    };

    if (!authenticated) {
        return <>{children}</>;
    }

    return (
        <PermissionContext.Provider value={perms}>
            {children}
        </PermissionContext.Provider>
    );
};
