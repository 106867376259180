import nProgress from "nprogress";
import { useEffect } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import NotAuthorized from "./NotAuthorized";
import NotFound from "./NotFound";
import ServerError from "./ServerError";
import UnavailableError from "./UnavailableError";
import UnknownError from "./UnknownError";

export default function RootBoundary() {
    const error = useRouteError();

    useEffect(() => {
        nProgress.done();
    }, []);

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <NotFound />;
        }

        if (error.status === 401) {
            return <NotAuthorized />;
        }

        if (error.status === 500) {
            return <ServerError />;
        }

        if (error.status === 503) {
            return <UnavailableError />;
        }
    }

    return <UnknownError error={error} />;
}
