import { BellIcon } from "@heroicons/react/24/outline";
import { BellAlertIcon } from "@heroicons/react/24/solid";
import React from "react";

interface Props {
    unread: number;
}

export default function NotificationsNavIcon({ unread }: Props) {
    return unread === 0 ? (
        <BellIcon className="flex-shrink-0 size-5 text-white md:text-gray-400" />
    ) : (
        <BellAlertIcon className="motion-safe:animate-[bounce_1s_5] size-5 cusror-pointer text-white md:text-red-500" />
    );
}
