export const MTTheme = {
    button: {
        defaultProps: {
            color: "red",
        },
    },
    buttonGroup: {
        defaultProps: {
            color: "red",
        },
    },
    checkbox: {
        defaultProps: {
            color: "red",
        },
    },
    input: {
        defaultProps: {
            color: "red",
        },
    },
    select: {
        defaultProps: {
            color: "red",
        },
    },
    textarea: {
        defaultProps: {
            color: "red",
        },
    },
};
