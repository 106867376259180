export function ApiUrl(
    endpoint: string,
    api_version = process.env.REACT_APP_API_VERSION,
    backend = false,
) {
    if (!backend) {
        return `${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_PATH}/${api_version}/${endpoint}`;
    }

    return `${process.env.REACT_APP_API_BASE}/${endpoint}`;
}

export function ApiAdminUrl(endpoint: string) {
    return `${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_PATH}/admin/${endpoint}`;
}
