import TextLogo from "src/Components/Logos/TextLogo";

export default function Terms() {
    return (
        <div className="font-sans text-gray-900 dark:text-gray-100">
            <div className="pt-4 bg-gray-100 dark:bg-gray-900">
                <div className="min-h-screen flex flex-col items-center py-6 md:pt-0">
                    <div className="w-72">
                        <TextLogo
                            classes="text-brand-600"
                            secondaryClasses="text-brand-500"
                        />
                    </div>
                    <div className="w-full md:max-w-4xl mt-6 p-6 bg-white dark:bg-gray-800 shadow-md text-sm overflow-hidden md:rounded-md prose dark:prose-invert">
                        <h1 className="mb-0">Terms of Service</h1>
                        <p className="my-0.5 text-gray-500">
                            Last Updated 13th July 2024
                        </p>
                        <h2>Agreement to our legal terms</h2>
                        <p>
                            Kart Social (&apos;<strong>Company</strong>&apos;,
                            &apos;
                            <strong>we</strong>&apos;, &apos;<strong>us</strong>
                            &apos;, or &apos;
                            <strong>our</strong>&apos;), a trading name of
                            Robert Trehy, registered in the United Kingdom at
                            _________.
                        </p>
                        <p>
                            We operate the website{" "}
                            <a
                                className="hover:text-brand-500"
                                href="https://kartsocial.com"
                            >
                                https://kartsocial.com
                            </a>{" "}
                            (the &apos;<strong>Site</strong>&apos;), as well as
                            any other related products and services that refer
                            or link to these legal terms (the &apos;
                            <strong>Legal Terms</strong>
                            &apos;) (collectively, the &apos;
                            <strong>Services</strong>&apos;).
                        </p>
                        <p>
                            You can contact us by email at{" "}
                            <a
                                className="hover:text-brand-500"
                                href="mailto:app@kartsocial.com"
                            >
                                app@kartsocial.com
                            </a>
                            .
                        </p>
                        <p>
                            These Legal Terms constitute a legally binding
                            agreement made between you, whether personally or on
                            behalf of and entity (&apos;<strong>you</strong>
                            &apos;), and Kart Social, concerning your acceess to
                            and use of the Services. You agree that by accessing
                            the Services, you have read, understood and agreed
                            to be bound by all of these Legal Terms. IF YOU DO
                            NOT AGREE WITH ALL OF THE THESE LEGAL TERMS, THEN
                            YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES
                            AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                        </p>
                        <p>
                            We will provide you with prior notice of any
                            scheduled changes to the Services you are using.
                            Changes to Legal Terms will be effective seven (7)
                            days after the notice is given, except if the
                            changes apply to new functionality, security
                            updates, bug fixes, and a court order, in which case
                            the changes will be effective immediately. By
                            continuing to use the Services after the effective
                            date of any changes, you agree to be bound by the
                            modified terms. If you disagree with such changes,
                            you may terminate Services as per the secion &apos;
                            <a href="#13-term-and-termination">
                                Term and Termination
                            </a>
                            &apos;.
                        </p>
                        <p>
                            The Services are intended for users who are least 13
                            years of age. All users who are minors in the
                            jurisdiction in which they reside (generally under
                            the age of 18) must have the persmission of, and be
                            directly supervised by, their parent or guardian to
                            use the Services. If you are a minor, you must have
                            your parent or guardian read and agree to these
                            Legal Terms prior to you using the Services.
                        </p>
                        <h2>Table of Contents</h2>
                        <ul>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#1-our-services"
                                >
                                    1. Our Services
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#2-intellectual-property-rights"
                                >
                                    2. Intellectual Property Rights
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#3-user-representations"
                                >
                                    3. User Representations
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#4-user-registration"
                                >
                                    4. User Registration
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#5-prohibited-activities"
                                >
                                    5. Prohibited Activities
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#6-user-generated-contributions"
                                >
                                    6. User Generated Contributions
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#7-contribution-licence"
                                >
                                    7. Contribution Licence
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#8-social-media"
                                >
                                    8. Social Media
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#9-third-party-websites-and-content"
                                >
                                    9. Third-Party Websites and Content
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#10-services-management"
                                >
                                    10. Services Management
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#11-privacy-policy"
                                >
                                    11. Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#12-copyright-infringements"
                                >
                                    12. Copyright Infringements
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#13-term-and-termination"
                                >
                                    13. Term and Termination
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#14-modifications-and-interruptions"
                                >
                                    14. Modifications and Interruptions
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#15-governing-law"
                                >
                                    15. Governing Law
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#16-dispute-resolution"
                                >
                                    16. Dispute Resolution
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#17-corrections"
                                >
                                    17. Corrections
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#18-disclaimer"
                                >
                                    18. Disclaimer
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#19-limitations-of-liability"
                                >
                                    19. Limitations of Liability
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#20-indemnification"
                                >
                                    20. Indemnification
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#21-user-data"
                                >
                                    21. User Data
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#22-electronic-communications-transactions-and-signatures"
                                >
                                    22. Electronic Communications, Transactions,
                                    and Signatures
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#23-california-users-and-residents"
                                >
                                    23. California Users and Residents
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#24-miscellaneous"
                                >
                                    24. Miscellaneous
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#25-contact-us"
                                >
                                    25. Contact Us
                                </a>
                            </li>
                        </ul>
                        <h2 id="1-our-services">1. Our Services</h2>
                        <p>
                            The information provided when using the Services is
                            not intended for distribution to or use by any
                            person or entity in any jurisdiction or country
                            where such distribution or use would be contrary to
                            law or regulation or which would subject us to any
                            registration requirement within such jurisdiction or
                            country. Accordingly, those persons who choose to
                            access the Services from other locations do so on
                            their own intiative and are solely responsible for
                            compliance with local laws, if and to the extent
                            local laws are applicable.
                        </p>
                        <p>
                            The Services are not tailored to comply with
                            industry-specific regulations (Health Insurance
                            Portability and Accountability Act (HIPAA), Federal
                            Information Information Security Management Act
                            (FISMA), etc.), so if your interactions would be
                            subjected to such laws, you may not use the
                            Services. You may not use the Services in a way that
                            would violate the Gramm-Leach-Bliley Act (GLBA).
                        </p>
                        <h2 id="2-intellectual-property-rights">
                            2. Intellectual Property Rights
                        </h2>
                        <h3>Our intellectual property</h3>
                        <p>
                            We are the owner or the licensee of all intellectual
                            property rights in our Services, including all
                            source code, databases, functionality, software,
                            website designs, audio, video, text, photographs,
                            and graphics in the Services (collectively, the
                            &apos;Content&apos;), as well as the trademarks,
                            service marks, and logos contained therein (the
                            &apos;Marks&apos;).
                        </p>
                        <p>
                            Our Content and Marks are protected by copyright and
                            trademark laws (and various other intellectual
                            property rights and unfair competition laws) and
                            treaties in the United States and around the world.
                        </p>
                        <p>
                            The Content and Marks are provided in or through the
                            Service &apos;AS IS&apos; for your personal,
                            non-commercial use or internal business purpose
                            only.
                        </p>
                        <h3>Your use of our Services</h3>
                        <p>
                            Subject to your compliance with these Legal Terms,
                            included the &apos;
                            <a href="#5-prohibited-activities">
                                Prohibited Activities
                            </a>
                            &apos; section below, we grant you a non-exclusive,
                            non-transferable, revocable licence to:
                        </p>
                        <ul>
                            <li>access the Services; and</li>
                            <li>
                                download or print a copy of any portion of the
                                Content to which you have properly gained
                                access.
                            </li>
                        </ul>
                        <p>
                            solely for your personal, non-commercial use or
                            internal business purpose.
                        </p>
                        <p>
                            Except as set out in this section or elsewhere in
                            our Legal Terms, no part of the Services and no
                            Content or Marks may be copied, reproduced,
                            aggregated, republished, uploaded, posted, publicly
                            displayed, encoded, translated, transmitted,
                            distributed, sold, licensed, or otherwise exploited
                            for any commercial purpose whatsoever, without our
                            express prior written permission.
                        </p>
                        <p>
                            If you wish to make use of the Services, Content, or
                            Marks other than as set out in this section or
                            elsewhere in our Legal Terms, please address your
                            request to:{" "}
                            <a href="mailto:app@kartsocial.com">
                                app@kartsocial.com
                            </a>
                            . If we ever grant you the permission to post,
                            reproduce, or publicly display any part of our
                            Services or Content, you must identify us as the
                            owners or licensors of the Services, Content, or
                            Marks and ensure that any copyright or proprietary
                            notice appears or is visible on posting,
                            reproducing, or displaying our Content.
                        </p>
                        <p>
                            We resvere all rights not expressly granted to you
                            in and to the Services, Content, and Marks.
                        </p>
                        <p>
                            Any breach of these Intellectual Property Rights
                            will constitute a material breach of our Legal Terms
                            and your right to use our Services will terminate
                            immediately.
                        </p>
                        <h3>Your submissions and contributions</h3>
                        <p>
                            Please review this section and the &apos;
                            <a
                                className="hover:text-brand-500"
                                href="#5-prohibited-activities"
                            >
                                Prohibited Activities
                            </a>
                            &apos; section carefully prior to using our Services
                            to understand the (a) rights you give us and (b)
                            obligations you have when you post or upload any
                            content through the Services.
                        </p>
                        <p>
                            <strong>Submissions:</strong> By directly sending us
                            any question, comment, suggestion, idea, feedback,
                            or other information about the Services
                            (&apos;Submissions&apos;), you agree to assign to us
                            all intellectual property rights in such Submission.
                            You agree that we shall own this Submission and be
                            entitled to its unrestricted use and dissemination
                            for any lawful purpose, commerical or otherwise,
                            without acknowledgment or compensation to you.
                        </p>
                        <p>
                            <strong>Contributions:</strong> The Services may
                            invite you to chat, contribute to, or participate in
                            blogs, message boards, online forums, and other
                            functionality during which you may create, submit,
                            post, display, transmit, publis, distribute, or
                            broadcast content and materials to us or through the
                            Services, including but not limited to text,
                            writings, video, audio, photographs, music,
                            graphics, comments, reviews, rating suggestions,
                            personal information, or other material
                            (&apos;Contributions&apos;). Any Submission that is
                            publicly posted shall also be treated as a
                            Contribution.
                        </p>
                        <p>
                            You understand that Contributions may be viewable by
                            other users of the Services and possible through
                            third-party websites.
                        </p>
                        <p>
                            <strong>
                                When you post Contributions, you grant us a
                                licence (including use of your name, trademarks,
                                and logos):
                            </strong>{" "}
                            By posting any Contributions, you grant us an
                            unrestricted, unlimited, irrevocable, perpetual,
                            non-exclusive, transferable, royalty-free,
                            fully-paid, worldwide right, and licence to: use,
                            copy, reproduce, distribute, sell, resell, publish,
                            broadcast, retitle, store, publicly perform,
                            publicly display, reformat, translate, excerpt (in
                            whole or in part), and exploit your Contributions
                            (including, without limitation, your image, name and
                            voice) for any purpose, commercial, advertising, or
                            otherwise, to prepare derivative works of, or
                            incorporate into other works, your Contributions,
                            and to sublicence the licences granted in this
                            section. Our use and distribution may occur in any
                            media formats and through any media channels.
                        </p>
                        <p>
                            This licence includes our use of your name, company
                            name, and franchise name, as applicable, and any of
                            the trademarks, service marks, trade names, logos
                            and personal and commercial images you provide.
                        </p>
                        <p>
                            <strong>
                                You are responsible for what you post or upload:
                            </strong>{" "}
                            By sending use Submissions and/or posting
                            Contributions through any part of the Services or
                            making Contributions accessible through the Services
                            by linking your account through the Services to any
                            of your social networking accounts, you:
                        </p>
                        <ul>
                            <li>
                                confirm that you have read and agree with our
                                &apos;
                                <a
                                    className="hover:text-brand-500"
                                    href="#5-prohibited-activities"
                                >
                                    Prohibited Activities
                                </a>
                                &apos; and will not post, send, publish, upload,
                                or transmit through the Services any Submission
                                nor post any Contribution that is illegal,
                                harassing, hateful, harmful, defamatory,
                                obscene, bullying, abusive, discriminatory,
                                threatening to any person or group, sexually
                                explicit, false, inaccurate, deceitful, or
                                misleading;
                            </li>
                            <li>
                                to the extent permissibile by aplicable law,
                                waive any and all moral rights to any such
                                Submission and/or Contribution;
                            </li>
                            <li>
                                warrant that any such Submission and/or
                                Contributions are original to you or that you
                                have the necessary rights and licences to submit
                                such Submissions and/or Contributions an dthat
                                you have full authority to grant us the
                                above-mentioned rights in relation to your
                                Submissions and/or Contributions; and
                            </li>
                            <li>
                                warrant and represent that your Submissions
                                and/or Contributions do not consitute
                                confidential information.
                            </li>
                        </ul>
                        <p>
                            You are solely responsible for your Submissions
                            and/or Contributions and you expressly agree to
                            reimburse us for any and all losses that we may
                            suffer because of your breach of (a) this section,
                            (b) any third party&apos;s intellectual property
                            rights, or (c) applicable law.
                        </p>
                        <p>
                            <strong>We may remove or edit your Content:</strong>{" "}
                            Although we have no obligation to monitor any
                            Contributions, we shall have the right to remove or
                            edit any Contributions at any time without notice if
                            in our reasonable opinion we consider such
                            Contributions harmful or in breach of these Legal
                            Terms. If we remove or edit any such Contributions,
                            we may also suspend or disable your account and
                            report you to the authorities.
                        </p>
                        <h3>Copyright infringement</h3>
                        <p>
                            We respect the intellectual property rights of
                            others. If you believe that any material available
                            on or through the Services infringes upon any
                            copyright you own or control, please immediately
                            refer to the &apos;
                            <a
                                className="hover:text-brand-500"
                                href="#12-copyright-infringements"
                            >
                                Copyright Infringements
                            </a>
                            &apos; section below.
                        </p>
                        <h2 id="3-user-representations">
                            3. User Representations
                        </h2>
                        <p>
                            By using the Services, you represent and warrant
                            that: (1) all registration information you submit
                            will be true, accurate, current, and complete; (2)
                            you will maintain the accuracy of such infmration
                            and promptly update such registration information as
                            necessary; (3) you have the legal capacity and you
                            agree to comply with these Legal Terms; (4) you are
                            not under the age of 13; (5) you are not a minor in
                            the jurisdiction in which you reside, or if a minor,
                            you have received parental permission to use the
                            Services; (6) you will not access the Services
                            through automated or non-human means, whether
                            through a bot, script or otherwise; (7) you will not
                            use the Services for any illegal or unauthorised
                            purpose; and (8) your use of the Services will not
                            violate any applicable law or regulation.
                        </p>
                        <p>
                            If you provide any information that is untrue,
                            inaccurate, not current, or incomplete, we have the
                            right to suspend or terminate your account and
                            refuse any and all current or future use of the
                            Services (or any portion thereof).
                        </p>
                        <h2 id="4-user-registration">4. User Registration</h2>
                        <p>
                            You may be required to register to use the Services.
                            You agree to keep your password confidential and
                            will be responsible for all use of your account and
                            password. We reserve the right to remove, reclaim,
                            or change a username you select if we determine, in
                            our sole discretion, that such username is
                            inappropriate, obscene, or otherwise objectionable.
                        </p>
                        <h2 id="5-prohibited-activities">
                            5. Prohibited Activities
                        </h2>
                        <p>
                            You may not access or use the Services for any
                            purpose other than that for which we make the
                            Services available. The Services may not be used in
                            connection with any commercial endeavours except
                            those that are specfically endorsed or approved by
                            us.
                        </p>
                        <p>As a user of the Services, you agree not to:</p>
                        <ul>
                            <li>
                                Systematically retrieve data or other content
                                from the Services to create or compile, directly
                                or indirectly, a collection, compilation,
                                database, or directory without written
                                permission from us.
                            </li>
                            <li>
                                Trick, defraud, or mislead us and other users,
                                especially in any attempt to learn sensitive
                                account information such as user passwords.
                            </li>
                            <li>
                                Circumvent, disable, or otherwise interfere with
                                security-related features of the Services,
                                including features that prevent or restrict the
                                use or copying of any Content or enforce
                                limitations on the use of the Services and/or
                                the Content contained therein.
                            </li>
                            <li>
                                Disparage, tarnish, or otherwise harm, in our
                                opinion, us and/or the Services.
                            </li>
                            <li>
                                Use any information obtained from the Services
                                in order to harass, abuse, or harm another
                                person.
                            </li>
                            <li>
                                Make improper use of our support services or
                                submit false reports of abuse or misconduct.
                            </li>
                            <li>
                                Use the Services in a manner inconsistent with
                                any applicable laws or regulations.
                            </li>
                            <li>
                                Engage in unauthorised framing of or linking to
                                the Services.
                            </li>
                            <li>
                                Upload or transmit (or attempt to upload or to
                                transmit) viruses, Trojan horses, or other
                                material, including excessive use of capital
                                letters and spamming (continuous posting of
                                repetitive text), that interferes with any
                                party&apos;s uninterrupted use and enjoyment of
                                the Services or modifies, impairs, disrupts,
                                alters, or interferes with the use, features,
                                functions, operation, or maintenance of the
                                Services.
                            </li>
                            <li>
                                Engage in any automated use of the system, such
                                as using scripts to send comments or messages,
                                or using any data mining, robots, or similar
                                data gathering and extraction tools.
                            </li>
                            <li>
                                Delete the copyright or other proprietary rights
                                notice from any Content.
                            </li>
                            <li>
                                Attempt to impersonate another user or person or
                                use the username of another user.
                            </li>
                            <li>
                                Upload or transmit (or attempt to upload or
                                transmit) any material that acts as a passive or
                                active information collection or transmission
                                mechanism, including without limitation, clear
                                graphics interchange formats (&apos;gifs&apos;),
                                1x1 pixels, web bugs, cookies, or other similar
                                devices (sometimes referred to as
                                &apos;spyware&apos; or &apos;passive collection
                                mechanisms&apos; or &apos;pcms&apos;).
                            </li>
                            <li>
                                Interfere with, disrupt, or create an undue
                                burden on the Services or the networks or
                                services connected to the Services.
                            </li>
                            <li>
                                Harass, annoy, intimidate, or threaten any of
                                our employees or agents engaged in providing any
                                portion of the Services to you.
                            </li>
                            <li>
                                Attempt to bypass any measures of the Services
                                designed to prevent or restrict access to the
                                Services, or any portion of the Services.
                            </li>
                            <li>
                                Copy or adapt the Services&apos; software,
                                including but not limited to Flash, PHP, HTML,
                                JavaScript and other code.
                            </li>
                            <li>
                                Except as permitted by applicable law, decipher,
                                decompile, disassemble, or reverse engineer any
                                of the software comprising or in any way making
                                up a part of the Services.
                            </li>
                            <li>
                                Except as may be the result og standard serch
                                engine or Internet Browser usage, use, launch,
                                develop, or distribute any automated system,
                                including without limitation, any spider, robot,
                                cheat utility, scraper, or offline reader that
                                accesses the Services, or use or launch any
                                unauthorised script or other software.
                            </li>
                            <li>
                                Use a buying agent or purchasing agent to make
                                purchases on the Services.
                            </li>
                            <li>
                                Make any unauthorised use of the Services,
                                including collecting usernames and/or email
                                addresses of users by electronic or other means
                                for the purpose of sending unsolicited email, or
                                creating user accounts by automated means or
                                under false pretences.
                            </li>
                            <li>
                                Use the Services as part of any effort to
                                compete with us or otherwise use the Services
                                and/or the Content for any revenue-generating
                                endeavour or commercial enterprise.
                            </li>
                            <li>
                                Use the Services to advertise or offer to sell
                                goods and services.
                            </li>
                            <li>Sell or otherwise transfer your profile.</li>
                        </ul>
                        <h2 id="6-user-generated-contributions">
                            6. User Generated Contributions
                        </h2>
                        <p>
                            The Services may invite you to chat, contribute to,
                            or participate in blogs, message boards, online
                            forums, and other functionality, and may provide you
                            with the opportunity to create, submit, post,
                            display, transmit, perform, publish, distribute, or
                            broadcast content and materials to us or on the
                            Services, including but not limited to text,
                            writings, video, audio, photographs, graphics,
                            comments, suggestions, or personal information or
                            other material (collectively,
                            &apos;Contributions&apos;). Contributions may not be
                            viewable by other users of the Services and through
                            third-party websites. As such, any Contributions you
                            transmit may be treated as non-confidential and
                            non-proprietary. When you create or make available
                            any Contributions, you thereby represent and warrant
                            that:
                        </p>
                        <ul>
                            <li>
                                The creation, distrbution, transmission, public
                                display, or performance, and the accessing,
                                downloading, or copying of your Contributions do
                                not and will not infringe the proprietary
                                rights, including but not limited to the
                                copyright, patent, trademark, trade secret, or
                                moral rights of any third party.
                            </li>
                            <li>
                                You are the creator and owner of or have the
                                necessary licences, rights, consents, releases,
                                and permissions to use and to authorise us, the
                                Services, and other users of the Services to use
                                your Contributions in any manner contemplated by
                                the Services and these Legal Terms.
                            </li>
                            <li>
                                You have the written consent, release, and/or
                                permission of each and every identifiable
                                individual person in your Contributions to use
                                the name or likeness of each and every such
                                identifiable individual person to enable
                                inclusion and use of your Contributions in any
                                manner contemplated by the Services and these
                                Legal Terms.
                            </li>
                            <li>
                                Your Contributions are not false, inaccurate, or
                                misleading.
                            </li>
                            <li>
                                Your Contributions are not unsolicited or
                                unauthorised advertising, promotional materials,
                                pyramid schemes, chain letters, spam, mass
                                mailings, or other forms of solicitation.
                            </li>
                            <li>
                                Your Contributions are not obscene, lewd,
                                lascivious, filthy, violent, harassing,
                                libellous, slanderous, or otherwise
                                objectionable (as determined by us).
                            </li>
                            <li>
                                Your Contributions do not ridicule, mock,
                                disparage, intimidate, or abuse anyone.
                            </li>
                            <li>
                                Your Contributions are not used to harass or
                                threaten (in the legal sense of those terms) any
                                other person and to promote violence against a
                                specific person or class of people.
                            </li>
                            <li>
                                Your Contributions do not violate any applicable
                                law, regulation, or rule.
                            </li>
                            <li>
                                Your Contributions do not violate the privacy or
                                publicity rights of any third party.
                            </li>
                            <li>
                                Your Contributions do not violate any applicable
                                law concerning child pornography, or otherwise
                                intended to protect the health or well-being of
                                minors.
                            </li>
                            <li>
                                Your Contributions do not include any offensive
                                comments that are connected to race, national
                                origin, gender, sexual preference, or physical
                                handicap.
                            </li>
                            <li>
                                Your Contributions do not otherwise violate, or
                                link to material that violates, any provision of
                                these Legal Terms, or any applicable law or
                                regulation.
                            </li>
                        </ul>
                        <p>
                            Any use of the Services in violation of the
                            foregoing violates these Legal Terms and may result
                            in, among other things, termination or suspension of
                            your rights to use the Services.
                        </p>
                        <h2 id="7-contribution-licence">
                            7. Contribution Licence
                        </h2>
                        <p>
                            By posting your Contributions to any part of the
                            Services or making Contributions accessible to the
                            Services by linking your account from the Services
                            to any of your social networking accounts, you
                            automatically grant, and you represent and warrant
                            that you have the right to grant, to us an
                            unrestricted, unlimited, irrevocable, perpetual,
                            non-exclusive, transferable, royalty-free,
                            fully-paid, worldwide right, and licence to host,
                            use, copy, reproduce, disclose, sell, resell,
                            publish, broadcast, retitle, archive, store, cache,
                            publicly perform, publicly display, reformat,
                            translate, transmit, excerpt (in whole or in part),
                            and distribute such Contributions (including,
                            without limitation, your image and voice) for any
                            purpose, commerical, advertising, or otherwise, and
                            to prepare derivative works of, or incorporate into
                            other works, such Contributions, and grant and
                            authorise sublicences of the foregoing. The use and
                            distribution may occur in any media formats and
                            through and media channels.
                        </p>
                        <p>
                            This licence will apply to any form, media, or
                            technology now known or hereafter developed, and
                            includes our use of your name, company name, and
                            franchise name, as applicable, and any of the
                            trademarks, service marks, trade names, logos, and
                            personal and commerical images you provide. You
                            waive all moral rights in your Contributions, and
                            you warrant that moral rights have not otherwise
                            been asserted in your Contributions.
                        </p>
                        <p>
                            We do not assert any ownership over your
                            Contributions. You retain full ownership of all of
                            your Contributions and any intellectual property
                            rights or other proprietary rights associated with
                            your Contributions. We are not liable for any
                            statements or representations in your Contributions
                            provided by you in any area on the Services. You are
                            solely responsible for your Contributions to the
                            Services and you expressly agree to exonerate us
                            from any and all responsibility and to refrain from
                            any legal action against us regarding your
                            Contributions.
                        </p>
                        <p>
                            We have the right, in our sole and absolute
                            discrtion, (1) to edit, redact, or otherwise change
                            any Contributions; (2) to re-categorise any
                            Contributions to place them in more appropriate
                            locations on the Services; and (3) to pre-screen or
                            delete any Contributions at any time and for any
                            reason, without notice. We have no obligation to
                            monitor your Contributions.
                        </p>
                        <h2 id="8-social-media">8. Social Media</h2>
                        <p>
                            As part of the functionality of the Services, you
                            may link your account with online accounts you have
                            with third-party service providers (each such
                            account, a &apos;Third-Party Account&apos;) by
                            either: (1) providing your Third-Party Account login
                            information through the Services; or (2) allowing us
                            to access your Third-Party Account, as is permitted
                            under the applicable terms and conditions that
                            govern your use of each Third-Party Account. You
                            represent and warrant that you are entitled to
                            disclose your Third-Party Account login information
                            to us and/or grant us access to your Third-Party
                            Account, without breach by you of any of the terms
                            and conditions that govern your use of the
                            applicable Third-Part Account, and without
                            obligating us to pay any fees or making us subject
                            to any usage limitation imposed by the third-party
                            service provider of the Third-Party Account. By
                            granting us access to any Third-Party Accounts, you
                            understand that (1) we may access, make available,
                            and store (if applicable) any content that you have
                            provided to and stored in your Third-Party Account
                            (the &apos;Social Network Content&apos;) so that it
                            is available on and through the Services via your
                            account, including without limitation any friend
                            lists and (2) we may submit to and recieve your
                            Third-Part Account additional information to the
                            extent you are notified when you link your account
                            with the Third-Party Account. Depending on the
                            Third-Party Account is terminated by the third-party
                            service provider, then Social Network Content may no
                            longer be available on and through the Services. You
                            will have the ability to disable the connection
                            between your account on the Services and your
                            Third-Party Accounts at any time. PLEASE NOTE THAT
                            YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
                            PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS
                            IS GOVERNED SOLELY BY YOUR AGGREEMENT(S) WITH SUCH
                            THIRD-PARTY SERVICE PROVIDERS. We make no effort to
                            review any Social Network Content for any purpose,
                            including but not limited to, for accuracy,
                            legality, or non-infringement, and we are not
                            responsible for any Social Network Content. You
                            acknowledge and agree that we may access your email
                            address book associated with a Third-Party Account
                            and your contacts list stored on your mobile device
                            or tablet computer solely for the purposes of
                            identifying and informing you of those contacts who
                            have also registered to use the Services. You can
                            deactivate the connection the Services and your
                            Third-Party Accoutn by contacting us using the
                            contact information below or through your account
                            settings (if applicable). We will attempt to delete
                            any information stored on our servers that was
                            obtained through such Third-Party Account, except
                            the username and profile picture that become
                            associated with your account.
                        </p>
                        <h2 id="9-third-party-websites-and-content">
                            9. Third-Party Websites and Content
                        </h2>
                        <p>
                            The Services may contain (or you may be sent via the
                            Site) links to other websites (&apos;Third-Party
                            Websites&apos;) as well as articles, photographs,
                            text, graphics, pictures, designs, music, sound,
                            video, information, applications, software, and
                            other content or items belonging to or originating
                            from third parties (&apos;Third-Party
                            Content&apos;). Such Third-Party Websites and
                            Third-Party Content are not investigated, monitored,
                            or checked for accuracy, appropriateness, or
                            completeness by us, and we are not responsible for
                            any Third-Party Websites accessed through the
                            Services or any Third-Party Content posted on,
                            available through, or installed from the Services,
                            including the content, accuracy, offensiveness,
                            opinions, reliability, privacy practices, or other
                            policies of or contained in the Third-Party Websites
                            or the Third-Party Content. Inclusion of, linking
                            to, or permitting the use or installation of an
                            Third-Party Websites or any Third-Party Content does
                            not imply approval or endorsement thereof by us. If
                            you decide to leave the Services and access the
                            Third-Party Websites or to use or install any
                            Third-Party Content, you do so at your own risk, and
                            you should be aware these Legal Terms no longer
                            govern. You should review the applicable terms and
                            policies, including privacy and data gather
                            practices, of any website to which you navigate from
                            the Services or relating to any applications you use
                            or install from the Services. Any purchases you make
                            through Third-Party Websites will be through other
                            websites and from other companies, and we take no
                            responsibility whatsoever in relation to such
                            purchases which are exclusively between you and the
                            applicable third party. You agree and acknowledge
                            that we do not endorse the products or services
                            offered on Third-Party Websites and you shall hold
                            us blameless from any harm caused by your purchase
                            of such products or services. Additionally, you
                            shall hold us blameless from any losses sustained by
                            you or harm caused to you relating to or resulting
                            in any way from any Third-Party Content or any
                            contact with Third-Party Websites.
                        </p>
                        <h2 id="10-services-management">
                            10. Services Management
                        </h2>
                        <p>
                            We reserve the right, but not the obligation, to:
                            (1) monitor the Services for violations of these
                            Legal Terms; (2) take appropriate legal action
                            against anyone who, in our sole discretion, violates
                            the law or these Legal Terms, including without
                            limitation, reporting such user to law enforcement
                            authorities; (3) in our sole discrtion and without
                            limitation, refuse, restrict access to, limit the
                            availability of, or disable (to the extent
                            technologically feasible) and of your Contributions
                            or any portion thereof; (4) in our sole discretion
                            and without limitation, notice, or liability, to
                            remove from the Services or otherwise disable all
                            files and content that are excessive in size or are
                            in any way burdensome to our systems; and (5)
                            otherwise manage the Services in a manner designed
                            to protect our rights and property and to facilitate
                            the proper functioning of the Services.
                        </p>
                        <h2 id="11-privacy-policy">11. Privacy Policy</h2>
                        <p>
                            We care about data privacy and security. Please
                            review our Privacy Policy:{" "}
                            <a href="https://kartsocial.com/privacy-policy">
                                https://kartsocial.com/privacy-policy
                            </a>
                            . By using the Services, you agree to be bound by
                            our Privacy Policy, which is incorporated into these
                            Legal Terms. Please be advised the Services are
                            hosted in the United States and Germany. If you
                            access the Services from any other region of the
                            world with laws or other requirements governing
                            personal data collection, use, or disclosure that
                            differ from applicable laws in the United States and
                            German, then through your continued use of the
                            Services, you are transferring your data to the
                            United States and Germany, and you expressly consent
                            to have your data transferred to and processed in
                            the United States and Germany. Further, we do not
                            knowingly accept, request, or solicit information
                            from children or knowingly market to children.
                            Therefore, in accordance with the U.S.
                            Children&apos;s Online Privacy Protection Act, if we
                            recieve actual knowledge the anyone under the age of
                            13 has provided personal inforamtion to us without
                            the requisite and verifiable parental consent, we
                            will delete that information from the Services as
                            quickly as is reasonably practical.
                        </p>
                        <h2 id="12-copyright-infringements">
                            12. Copyright Infringements
                        </h2>
                        <p>
                            We respect the intellectual property rights of
                            others. If you believe that any material available
                            or through the Services infringes upon any copyright
                            you own or control, please immediately notify us
                            using the contact information provided below (a
                            &apos;Notification&apos;). A copy of your
                            Notification will be sent to the person who posted
                            or stored the material addressed in the
                            Notification. Please be advised the pursuant to
                            applicable law you may be held liable for damages if
                            you make material misrepresentations in a
                            Notification. Thus, if you are not sure that
                            material located on or link to by the Services
                            infringes your copyright, you should consider first
                            contacting an attorney.
                        </p>
                        <h2 id="13-term-and-termination">
                            13. Term and Termination
                        </h2>
                        <p>
                            These Legal Terms shall remain in full force and
                            effect while you use the Services. WITHOUT LIMITING
                            ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE
                            TEH RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
                            NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
                            SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES),
                            TO ANY PERSON FOR ANY LEGAL TERMS OR OF ANY
                            APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
                            USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR
                            ACCEOUNT AND ANY CONTENT OR INFORMATION THAT YOU
                            POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                            DISCRETION.
                        </p>
                        <p>
                            If we terminate or suspend your account for any
                            reason, you are prohibited from registering and
                            creating a new account under your name, a fake or
                            borrowed name, or the name of any third party, even
                            if you may be acting on behalf of the third party.
                            In addition to terminating or suspending your
                            account, we reserve the right to take appropriate
                            legal action, including without limitation pursuing
                            civil, criminal, and injunctive redress.
                        </p>
                        <h2 id="14-modifications-and-interruptions">
                            14. Modifications and Interruptions
                        </h2>
                        <p>
                            We reserve the right to change, modify, or remove
                            the contents of the Services at any time or for any
                            reason at our sole discretion wihtout notice.
                            However, we have no obligation to update any
                            information on our Services. We will not be liable
                            to you or any third party for any modification,
                            price change, suspension, or discontinuance of the
                            Services.
                        </p>
                        <p>
                            We cannot guarantee the Services will be available
                            at all times. We may experience hardware, software,
                            or other problems or need to perform maintenance
                            related to the Services, resulting in interruptions,
                            delays, or errors. We reserve the righ tot change,
                            revise, update, suspend, discontinue, or otherwise
                            modify the Services during any downtime or
                            discontinuance of the Services. Nothing in these
                            Legal Terms will be construed to obligate us to
                            maintain and support the Services or to supply any
                            corrections, updates, or releases in connection
                            therewith.
                        </p>
                        <h2 id="15-governing-law">15. Governing Law</h2>
                        <p>
                            These Legal Terms are governed by and interpreted
                            following the laws of England and Wales, and the use
                            of the United Nations Convention of Contracts for
                            the International Sales of Goods is expressly
                            excluded. If you habitual residence is in the EU,
                            and you are a consumer, you additionally possess the
                            portection provided to you by obligatory provisions
                            of the law in your country to residence. Kart Social
                            and yourself both agree to submit to the
                            non-exclusive jurisdition of the courts of England,
                            which means that you may make a claim to defend your
                            consumer protection rights in regards to these Legal
                            Terms in Englangd, or in the EU country in which you
                            reside.
                        </p>
                        <h2 id="16-dispute-resolution">
                            16. Dispute Resolution
                        </h2>
                        <h3>Information Negotiations</h3>
                        <p>
                            To expedite resolution and control the cost of any
                            dispute, controversy, or claim related to these
                            Legal Terms (each a &apos;Dispute&apos; and
                            collectively, the &apos;Disputes&apos;) brought by
                            either you or us (individually, a &apos;Party&apos;
                            and collectively, the &apos;Parties&apos;), the
                            Parties agree to first attempt to negotiate any
                            Dispute (except those Disputes expressly provided
                            below) informally for at least thirty (30) days
                            before initiating arbitration. Such informal
                            negotiations commence upon written notice from one
                            Party to the other Party.
                        </p>
                        <h3>Binding Arbitration</h3>
                        <p>
                            Any dispute arising from the relationship between
                            the Parties to these Legal Terms shall be determined
                            by one arbitrator who will be chosen in accordance
                            with the Arbitration and Internal Rules of the
                            European Court of Arbitration being part of the
                            European Centre of Arbitration having its seat in
                            Strasbourg, and which are in force at the time the
                            application for artbitration is filed, and of which
                            adoption of this clause constitutes acceptance. The
                            seat of arbitration shall be Sheffield, England. The
                            language of the proceedings shall be English.
                            Applicable rules of substantive law shall be the law
                            of England.
                        </p>
                        <h3>Restrictions</h3>
                        <p>
                            The Parties agree that any arbitration shall be
                            limited to the Dispute between the Parties
                            individually. To the full extent permitted by law,
                            (a) no arbitration shall be joined with any other
                            proceeding; (b) there is no right or authority for
                            any Dispute to be arbitrated on a class-action basis
                            or to utilise class action procedures; and (c) there
                            is no right or authority for any Dispute to be
                            brought in a purported representative capacity on
                            behalf of the general public or any other persons.
                        </p>
                        <h3>
                            Exceptions to Informal Negotiations and Arbitration
                        </h3>
                        <p>
                            The Parties agree that the following Disputes are
                            not subject to the above provisions concerning
                            informal negotiations binding arbitration: (a) any
                            Disputes seeking to enforce or protect, or
                            concerning the validity of, any of the intellectual
                            property rights of a Party; (b) any Dispute related
                            to, or arising from, allegations of theft, piracy,
                            invasion of privacy, or unauthorised use; and (c)
                            any claim for injunctive relief. If this provision
                            is found to be illegal or unenforceable, then
                            neither Party will elect to arbitrate any Dispute
                            failling within that portion of this provision found
                            to be illegal or uneforceable and such Dispute shall
                            be decided by a court of competent jurisdiction
                            within the courts listed for jurisdiction above, and
                            the Parties agree to submit to the personal
                            jurisdiction of that court.
                        </p>
                        <h2 id="17-corrections">17. Corrections</h2>
                        <p>
                            There may be information on the Services that
                            contains typographical errors, inaccuracies, or
                            omissions, including descriptions, pricing,
                            availability, and various other information. We
                            reserve the right to correct any errors,
                            inaccuracies, or omissions and to change or update
                            the information on the Services at any time, without
                            prior notice.
                        </p>
                        <h2 id="18-disclaimer">18. Disclaimer</h2>
                        <p>
                            THE SERVICES ARE PROVIDED ON AN AS-IS AND
                            AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE
                            SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
                            EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                            EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES
                            AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION,
                            THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                            FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE
                            MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
                            ACCURACY OR COMPLETENESS OF THE SERVICES&apos;
                            CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE
                            APPLICATIONS LINK TO THE SERVICES AND WE WILL ASSUME
                            NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
                            MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
                            (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
                            NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
                            USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO
                            OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                            PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
                            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                            TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
                            VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                            TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD
                            PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
                            CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
                            ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
                            CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
                            AVAILABLE VIA THTE SERVICES. WE DO NOT WARRANT,
                            ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
                            PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD
                            PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE,
                            OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
                            BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
                            PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                            ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
                            PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
                            PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM
                            OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                            JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                        </p>
                        <h2 id="19-limitations-of-liability">
                            19. Limitations of Liability
                        </h2>
                        <p>
                            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR
                            AGENTS BE LIABLE TO YOUR OR ANY THIRD PARTY FOR ANY
                            DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY,
                            INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
                            LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
                            DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN
                            IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                            DAMAGES. NOWITHSTANDING ANYTHING TO THE CONTRARY
                            CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
                            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION,
                            WILL AT ALL TIMES BE LIMITED OT THE AMOUNT PAID, IF
                            ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD
                            PRIOR TO ANY CAUSE OF ACTION ARISING. CERTAIN US
                            STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
                            LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION
                            OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
                            APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS
                            OR LIMITEATIONS MAY NOT APPLY TO YOU, AND YOU HAVE
                            ADDITIONAL RIGHTS.
                        </p>
                        <h2 id="20-indemnification">20. Indemnification</h2>
                        <p>
                            You agree to defend, indemnify, and hold us
                            harmless, including our subsidiaries, affiliates,
                            and all of our respective officers, agents,
                            partners, and employees, from and against any loss,
                            damage, liability, claim, or demand, including
                            reasonable attorneys&apos; fees and expenses, made
                            by any third party due to or arising out of: (1)
                            your Contributions; (2) use of the Services; (3)
                            breach of these Legal Terms; (4) any breach of your
                            representations and warranties set forth in these
                            Legal Terms; (5) your violation of the rights of a
                            third party, including but not limited to
                            intellectual property rights; or (6) any overt
                            harmful act toward any other user of the Services
                            with whom you connected via the Services.
                            Notwithstanding the foregoing, we reserve the right,
                            at your expense, to assume the exclusive defence and
                            control of any matter for which you are required to
                            indemnify us, and you agree to cooperate, at your
                            expense, with our defence of such claims. We will us
                            reasonable efforts to notify you of any such claim,
                            action, or proceeding which is subject to this
                            indemnification upon becoming aware of it.
                        </p>
                        <h2 id="21-user-data">21. User Data</h2>
                        <p>
                            We will maintain certain data taht you transmit to
                            the Services for the purpose of managing the
                            performance of the Services, as well as data
                            relating to your use of the Services. Although we
                            perform regular routine backups of data, you are
                            solely responsible for all data that you transmit or
                            that relates to any activity you have undertaken
                            using the Services. You agree that we shall have no
                            liability to you for any loss or corruption of any
                            such data, and you hereby waive any right of action
                            against us arising from any such loss or corruption
                            of such data.
                        </p>
                        <h2 id="22-electronic-communications-transactions-and-signatures">
                            22. Electronic Communications, Transactions, and
                            Signatures
                        </h2>
                        <p>
                            Visiting the Services, sending us emails, and
                            completing online forms constitute electronic
                            communications. You consent to receive electronic
                            communications, and you agree that all agreements,
                            notices, disclosures, and other communications we
                            provide to you electronically, via email and on the
                            Services, satisfy any legal requirement that such
                            communication be in writing. YOU HEREBY AGREE TO THE
                            USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
                            OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                            NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                            INITIATED OR COMPLETED BY US OR VIA THE SERVICES.
                            You hereby waive any rights or requirements under
                            any statutes, regulations, rules, ordinances, or
                            other laws in any jurisdiction which require any
                            original signature or delivery or retention of
                            non-electronic records, or to payments or the
                            granting of credits by any means other than
                            electronic means.
                        </p>
                        <h2 id="23-california-users-and-residents">
                            23. California Users and Residents
                        </h2>
                        <p>
                            If any complaint with us is not statisfactorily
                            resolved, you can contact the Complaint Assistance
                            Unit of the Division of Consumer Services of the
                            California Department of Consumer Affairs in writing
                            at 1625 North Market Blvd., Suite N 112, Sacramento,
                            California 95834 or by telephone at (800) 952-5210
                            or (916) 445-1254.
                        </p>
                        <h2 id="24-miscellaneous">24. Miscellaneous</h2>
                        <p>
                            These Legal Terms and any policies or operating
                            rules posted by us on the Services or in respect to
                            the Services constitute the entire agreement and
                            understanding between you and us. Our failure to
                            exercise or enforce any right or provision of these
                            Legal Terms shall not operate as a waiver of such
                            right or provision. These LEgal Terms operate to the
                            fullest extent permissible by law. We may assign any
                            or all of our rights and obligations to others at
                            any time. We shall not be responsible or liable for
                            any loss, damage, delay or failure to act caused by
                            any cause beyond our reasonable control. If any
                            provision or part of a provision of these Legal
                            Terms is determined to be unlawful, void, or
                            unenforceable, that provision or part of the
                            provision is deemed severable from these Legal Terms
                            and does not affect the validity and enforceability
                            of any remaining provisions. There is no joint
                            venture, partnership, employment or agency
                            relationship created between you and us as a result
                            of these Legal Terms or us of the Services. You
                            agree that these Legal Terms will not be constued
                            against us by virtue of having drafted them. You
                            hereby waive any and all defences you may have based
                            on the electronic form of these Legal Terms and the
                            lack of signing by the parties hereto to execute
                            these Legal Terms.
                        </p>
                        <h2 id="25-contact-us">25. Contact Us</h2>
                        <p>
                            In order to resolve a complaint regarding the
                            Services or to receive further information regarding
                            use of the Services, please contact us at:
                        </p>
                        <p>
                            <strong>Kart Social</strong>
                            <br />
                            <strong>
                                <a
                                    className="hover:text-brand-500"
                                    href="mailto:app@kartsocial.com"
                                >
                                    app@kartsocial.com
                                </a>
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
