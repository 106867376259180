export default async function loadMomentLocale(locale) {
    // Requires a switch because dynamic imports don't support string interpolation
    switch (locale) {
        case "af":
            await import("moment/dist/locale/af");
            break;
        case "ar-dz":
            await import("moment/dist/locale/ar-dz");
            break;
        case "ar-kw":
            await import("moment/dist/locale/ar-kw");
            break;
        case "ar-ly":
            await import("moment/dist/locale/ar-ly");
            break;
        case "ar-ma":
            await import("moment/dist/locale/ar-ma");
            break;
        case "ar-sa":
            await import("moment/dist/locale/ar-sa");
            break;
        case "ar-tn":
            await import("moment/dist/locale/ar-tn");
            break;
        case "ar":
            await import("moment/dist/locale/ar");
            break;
        case "az":
            await import("moment/dist/locale/az");
            break;
        case "be":
            await import("moment/dist/locale/be");
            break;
        case "bg":
            await import("moment/dist/locale/bg");
            break;
        case "bm":
            await import("moment/dist/locale/bm");
            break;
        case "bn-bd":
            await import("moment/dist/locale/bn-bd");
            break;
        case "bn":
            await import("moment/dist/locale/bn");
            break;
        case "bo":
            await import("moment/dist/locale/bo");
            break;
        case "br":
            await import("moment/dist/locale/br");
            break;
        case "bs":
            await import("moment/dist/locale/bs");
            break;
        case "ca":
            await import("moment/dist/locale/ca");
            break;
        case "cs":
            await import("moment/dist/locale/cs");
            break;
        case "cv":
            await import("moment/dist/locale/cv");
            break;
        case "cy":
            await import("moment/dist/locale/cy");
            break;
        case "da":
            await import("moment/dist/locale/da");
            break;
        case "de-at":
            await import("moment/dist/locale/de-at");
            break;
        case "de-ch":
            await import("moment/dist/locale/de-ch");
            break;
        case "de":
            await import("moment/dist/locale/de");
            break;
        case "dv":
            await import("moment/dist/locale/dv");
            break;
        case "el":
            await import("moment/dist/locale/el");
            break;
        case "en-au":
            await import("moment/dist/locale/en-au");
            break;
        case "en-ca":
            await import("moment/dist/locale/en-ca");
            break;
        case "en-gb":
            await import("moment/dist/locale/en-gb");
            break;
        case "en-ie":
            await import("moment/dist/locale/en-ie");
            break;
        case "en-il":
            await import("moment/dist/locale/en-il");
            break;
        case "en-in":
            await import("moment/dist/locale/en-in");
            break;
        case "en-nz":
            await import("moment/dist/locale/en-nz");
            break;
        case "en-sg":
            await import("moment/dist/locale/en-sg");
            break;
        case "eo":
            await import("moment/dist/locale/eo");
            break;
        case "es-do":
            await import("moment/dist/locale/es-do");
            break;
        case "es-mx":
            await import("moment/dist/locale/es-mx");
            break;
        case "es-us":
            await import("moment/dist/locale/es-us");
            break;
        case "es":
            await import("moment/dist/locale/es");
            break;
        case "et":
            await import("moment/dist/locale/et");
            break;
        case "eu":
            await import("moment/dist/locale/eu");
            break;
        case "fa":
            await import("moment/dist/locale/fa");
            break;
        case "fi":
            await import("moment/dist/locale/fi");
            break;
        case "fil":
            await import("moment/dist/locale/fil");
            break;
        case "fo":
            await import("moment/dist/locale/fo");
            break;
        case "fr-ca":
            await import("moment/dist/locale/fr-ca");
            break;
        case "fr-ch":
            await import("moment/dist/locale/fr-ch");
            break;
        case "fr":
            await import("moment/dist/locale/fr");
            break;
        case "fy":
            await import("moment/dist/locale/fy");
            break;
        case "gd":
            await import("moment/dist/locale/gd");
            break;
        case "gl":
            await import("moment/dist/locale/gl");
            break;
        case "gom-latn":
            await import("moment/dist/locale/gom-latn");
            break;
        case "gu":
            await import("moment/dist/locale/gu");
            break;
        case "he":
            await import("moment/dist/locale/he");
            break;
        case "hi":
            await import("moment/dist/locale/hi");
            break;
        case "hr":
            await import("moment/dist/locale/hr");
            break;
        case "hu":
            await import("moment/dist/locale/hu");
            break;
        case "hy-am":
            await import("moment/dist/locale/hy-am");
            break;
        case "id":
            await import("moment/dist/locale/id");
            break;
        case "is":
            await import("moment/dist/locale/is");
            break;
        case "it-ch":
            await import("moment/dist/locale/it-ch");
            break;
        case "it":
            await import("moment/dist/locale/it");
            break;
        case "ja":
            await import("moment/dist/locale/ja");
            break;
        case "jv":
            await import("moment/dist/locale/jv");
            break;
        case "ka":
            await import("moment/dist/locale/ka");
            break;
        case "kk":
            await import("moment/dist/locale/kk");
            break;
        case "km":
            await import("moment/dist/locale/km");
            break;
        case "kn":
            await import("moment/dist/locale/kn");
            break;
        case "ko":
            await import("moment/dist/locale/ko");
            break;
        case "ku":
            await import("moment/dist/locale/ku");
            break;
        case "ky":
            await import("moment/dist/locale/ky");
            break;
        case "lb":
            await import("moment/dist/locale/lb");
            break;
        case "lo":
            await import("moment/dist/locale/lo");
            break;
        case "lt":
            await import("moment/dist/locale/lt");
            break;
        case "lv":
            await import("moment/dist/locale/lv");
            break;
        case "me":
            await import("moment/dist/locale/me");
            break;
        case "mi":
            await import("moment/dist/locale/mi");
            break;
        case "mk":
            await import("moment/dist/locale/mk");
            break;
        case "ml":
            await import("moment/dist/locale/ml");
            break;
        case "mn":
            await import("moment/dist/locale/mn");
            break;
        case "mr":
            await import("moment/dist/locale/mr");
            break;
        case "ms-my":
            await import("moment/dist/locale/ms-my");
            break;
        case "ms":
            await import("moment/dist/locale/ms");
            break;
        case "mt":
            await import("moment/dist/locale/mt");
            break;
        case "my":
            await import("moment/dist/locale/my");
            break;
        case "nb":
            await import("moment/dist/locale/nb");
            break;
        case "ne":
            await import("moment/dist/locale/ne");
            break;
        case "nl-be":
            await import("moment/dist/locale/nl-be");
            break;
        case "nl":
            await import("moment/dist/locale/nl");
            break;
        case "nn":
            await import("moment/dist/locale/nn");
            break;
        case "pa-in":
            await import("moment/dist/locale/pa-in");
            break;
        case "pl":
            await import("moment/dist/locale/pl");
            break;
        case "pt-br":
            await import("moment/dist/locale/pt-br");
            break;
        case "pt":
            await import("moment/dist/locale/pt");
            break;
        case "ro":
            await import("moment/dist/locale/ro");
            break;
        case "ru":
            await import("moment/dist/locale/ru");
            break;
        case "sd":
            await import("moment/dist/locale/sd");
            break;
        case "se":
            await import("moment/dist/locale/se");
            break;
        case "si":
            await import("moment/dist/locale/si");
            break;
        case "sk":
            await import("moment/dist/locale/sk");
            break;
        case "sl":
            await import("moment/dist/locale/sl");
            break;
        case "sq":
            await import("moment/dist/locale/sq");
            break;
        case "sr-cyrl":
            await import("moment/dist/locale/sr-cyrl");
            break;
        case "sr":
            await import("moment/dist/locale/sr");
            break;
        case "ss":
            await import("moment/dist/locale/ss");
            break;
        case "sv":
            await import("moment/dist/locale/sv");
            break;
        case "sw":
            await import("moment/dist/locale/sw");
            break;
        case "ta":
            await import("moment/dist/locale/ta");
            break;
        case "te":
            await import("moment/dist/locale/te");
            break;
        case "tet":
            await import("moment/dist/locale/tet");
            break;
        case "tg":
            await import("moment/dist/locale/tg");
            break;
        case "th":
            await import("moment/dist/locale/th");
            break;
        case "tk":
            await import("moment/dist/locale/tk");
            break;
        case "tl-ph":
            await import("moment/dist/locale/tl-ph");
            break;
        case "tlh":
            await import("moment/dist/locale/tlh");
            break;
        case "tr":
            await import("moment/dist/locale/tr");
            break;
        case "tzl":
            await import("moment/dist/locale/tzl");
            break;
        case "tzm-latn":
            await import("moment/dist/locale/tzm-latn");
            break;
        case "tzm":
            await import("moment/dist/locale/tzm");
            break;
        case "ug-cn":
            await import("moment/dist/locale/ug-cn");
            break;
        case "uk":
            await import("moment/dist/locale/uk");
            break;
        case "ur":
            await import("moment/dist/locale/ur");
            break;
        case "uz-latn":
            await import("moment/dist/locale/uz-latn");
            break;
        case "uz":
            await import("moment/dist/locale/uz");
            break;
        case "vi":
            await import("moment/dist/locale/vi");
            break;
        case "x-pseudo":
            await import("moment/dist/locale/x-pseudo");
            break;
        case "yo":
            await import("moment/dist/locale/yo");
            break;
        case "zh-cn":
            await import("moment/dist/locale/zh-cn");
            break;
        case "zh-hk":
            await import("moment/dist/locale/zh-hk");
            break;
        case "zh-mo":
            await import("moment/dist/locale/zh-mo");
            break;
        case "zh-tw":
            await import("moment/dist/locale/zh-tw");
            break;
        default:
            await import("moment/dist/locale/en-gb");
            break;
    }
}
