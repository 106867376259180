import { Link } from "react-router-dom";

import ProfilePhoto from "../../UserProfile/ProfilePhoto";

export default function ResultItemPerson({ result }: any) {
    return (
        <Link
            to={`/@${result.username}`}
            className="py-2 px-3 group hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer flex flex-row gap-x-3 items-center"
        >
            <ProfilePhoto size="xs" user={result} />
            <div className="font-semibold group-hover:text-brand-500 truncate">
                {result.username}
            </div>
        </Link>
    );
}
