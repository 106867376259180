import {
    ChevronDownIcon,
    ShieldExclamationIcon,
} from "@heroicons/react/24/outline";
import {
    Button,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList,
    Typography,
} from "@material-tailwind/react";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { PermissionContext } from "src/Providers/Authentication";

export default function AdminNavigation() {
    const permissions = useContext(PermissionContext);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const closeMenu = () => setIsMenuOpen(false);

    if (!permissions || !permissions.includes("admin.access")) {
        return null;
    }

    return (
        <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
            <MenuHandler>
                <Button
                    variant="text"
                    className="flex items-center gap-1 h-10 px-2 lg:ml-auto"
                >
                    <ShieldExclamationIcon className="size-5 text-white md:text-gray-400" />
                    <span className="sr-only">Administration</span>
                    <ChevronDownIcon
                        strokeWidth={2.5}
                        className={`h-3 w-3 text-white md:text-gray-400 transition-transform ${isMenuOpen ? "rotate-180" : ""}`}
                    />
                </Button>
            </MenuHandler>
            <MenuList className="p-1 dark:bg-gray-800 dark:border-gray-700">
                <Link to="/admin/dashboard">
                    <MenuItem onClick={closeMenu}>
                        <Typography
                            as="span"
                            variant="small"
                            className="font-normal"
                        >
                            Dashboard
                        </Typography>
                    </MenuItem>
                </Link>
                <Link to="/admin/users">
                    <MenuItem onClick={closeMenu}>
                        <Typography
                            as="span"
                            variant="small"
                            className="font-normal"
                        >
                            Users
                        </Typography>
                    </MenuItem>
                </Link>
                <Link to="/admin/help/solutions">
                    <MenuItem onClick={closeMenu}>
                        <Typography
                            as="span"
                            variant="small"
                            className="font-normal"
                        >
                            Solutions
                        </Typography>
                    </MenuItem>
                </Link>
                <Link to="/admin/help/tickets">
                    <MenuItem onClick={closeMenu}>
                        <Typography
                            as="span"
                            variant="small"
                            className="font-normal"
                        >
                            Tickets
                        </Typography>
                    </MenuItem>
                </Link>
                <div className="border-t border-gray-200 dark:border-gray-600"></div>
                <a href="/log-viewer">
                    <MenuItem onClick={closeMenu}>
                        <Typography
                            as="span"
                            variant="small"
                            className="font-normal"
                        >
                            Log Viewer
                        </Typography>
                    </MenuItem>
                </a>
                <a href="/telescope">
                    <MenuItem onClick={closeMenu}>
                        <Typography
                            as="span"
                            variant="small"
                            className="font-normal"
                        >
                            Telescope
                        </Typography>
                    </MenuItem>
                </a>
            </MenuList>
        </Menu>
    );
}
