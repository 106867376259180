import {
    CalendarDaysIcon,
    ChevronDownIcon,
    GlobeAltIcon,
    HomeIcon,
} from "@heroicons/react/24/outline";
import {
    Cog6ToothIcon,
    KeyIcon,
    UserCircleIcon,
    UserPlusIcon,
} from "@heroicons/react/24/solid";
import {
    Button,
    IconButton,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList,
    Navbar as NavbarMT,
    Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSanctum } from "react-sanctum";

// import { ToBool } from "../../Helpers/ToBool";
import InitialsLogo from "../Logos/InitialsLogo";
import TextLogo from "../Logos/TextLogo";
import Notifications from "../Notifications/Notifications";
import AppSearch from "../Search/AppSearch";
import ProfilePhoto from "../UserProfile/ProfilePhoto";
import AdminNavigation from "./AdminNavigation";
import NavLink from "./NavLink";

export default function Navbar() {
    const location = useLocation();
    const { authenticated, user } = useSanctum();

    const [white, setWhite] = useState<boolean>(
        location.pathname === "/tracks",
    );
    // const [isDarkMode, setDarkMode] = useState<boolean>(false);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    let max_invites = +(process.env.REACT_APP_MAX_INVITES || 0) as number;

    // const changeDarkMode = (checked: boolean) => {
    //     setDarkMode(checked);

    //     if (checked) {
    //         localStorage.setItem('darkMode', 'true');
    //         document.documentElement.classList.add('dark');
    //     } else {
    //         localStorage.removeItem('darkMode');
    //         document.documentElement.classList.remove('dark');
    //     }
    // }

    useEffect(() => {
        // const darkMode = ToBool(localStorage.getItem('darkMode'));
        // if (darkMode) {
        //     changeDarkMode(darkMode);
        // }

        window.addEventListener("scroll", () => {
            if (location.pathname !== "tracks") {
                setWhite(window.scrollY >= 1);
            }
        });
    }, []);

    useEffect(() => {
        setWhite(location.pathname === "/tracks");
    }, [location]);

    const closeMenu = () => setIsMenuOpen(false);

    return (
        <NavbarMT
            color={white ? "white" : "transparent"}
            fullWidth
            className="sticky border-0 top-0 z-10 h-max px-0 py-0"
        >
            <div className="container mx-auto flex items-center justify-between py-4 bg-brand-800 dark:bg-brand-900 md:bg-transparent dark:md:bg-transparent">
                <div className="flex flex-row gap-x-8">
                    <Link to={authenticated ? "/dashboard" : "/tracks"}>
                        <div className="hidden md:flex h-9">
                            <TextLogo
                                classes="text-brand-600"
                                secondaryClasses="text-brand-500"
                            />
                        </div>
                        <div className="ms-5 md:hidden text-4xl">
                            <InitialsLogo
                                classes="text-white dark:text-gray-300"
                                secondaryClasses="text-gray-200 dark:text-gray-400"
                            />
                        </div>
                    </Link>
                    <div className="hidden md:flex items-center gap-3">
                        <Link to="/dashboard">
                            <IconButton
                                variant={
                                    location.pathname.startsWith("/dashboard")
                                        ? "filled"
                                        : "text"
                                }
                                color="red"
                            >
                                <HomeIcon className="size-5" />
                            </IconButton>
                        </Link>
                        <Link to="/tracks">
                            <IconButton
                                variant={
                                    location.pathname.startsWith("/tracks")
                                        ? "filled"
                                        : "text"
                                }
                                color="red"
                            >
                                <GlobeAltIcon className="size-5" />
                            </IconButton>
                        </Link>
                        <Link to="/events">
                            <IconButton
                                variant={
                                    location.pathname.startsWith("/events")
                                        ? "filled"
                                        : "text"
                                }
                                color="red"
                            >
                                <CalendarDaysIcon className="size-5" />
                            </IconButton>
                        </Link>
                    </div>
                </div>
                <div className="flex items-center gap-1 pr-2">
                    {/* <AppSearch /> */}
                    <AppSearch />
                    {authenticated ? (
                        <>
                            <Notifications />
                            {/* TODO: Reinstate Dark Mode with MT3 */}
                            {/* <IconButton variant="text">
                                    <div className="text-white md:text-gray-400">
                                        <DarkModeSwitch
                                            checked={isDarkMode}
                                            onChange={changeDarkMode}
                                            size={20}
                                            sunColor="currentColor"
                                            moonColor="currentColor"
                                        />
                                    </div>
                                </IconButton> */}
                            <AdminNavigation />
                            <Menu
                                open={isMenuOpen}
                                handler={setIsMenuOpen}
                                placement="bottom-end"
                            >
                                <MenuHandler>
                                    <Button
                                        variant="text"
                                        className="flex items-center gap-1 py-0.5 pl-0.5 pr-2 lg:ml-auto"
                                    >
                                        <ProfilePhoto
                                            user={user}
                                            size="sm"
                                            variant="rounded"
                                        />
                                        <span className="sr-only">
                                            {user?.username}
                                        </span>
                                        <ChevronDownIcon
                                            strokeWidth={2.5}
                                            className={`size-3 text-white md:text-gray-400 transition-transform  ${isMenuOpen ? "rotate-180" : ""}`}
                                        />
                                    </Button>
                                </MenuHandler>
                                <MenuList>
                                    <Link to={`/@${user?.username}`}>
                                        <MenuItem
                                            onClick={closeMenu}
                                            className="flex items-center gap-2 rounded"
                                        >
                                            <UserCircleIcon
                                                className="size-4"
                                                strokeWidth={2}
                                            />
                                            <Typography
                                                as="span"
                                                variant="small"
                                                className="font-normal"
                                            >
                                                View Profile
                                            </Typography>
                                        </MenuItem>
                                    </Link>
                                    <Link to="/user/account">
                                        <MenuItem
                                            onClick={closeMenu}
                                            className="flex items-center gap-2 rounded"
                                        >
                                            <Cog6ToothIcon
                                                className="size-4"
                                                strokeWidth={2}
                                            />
                                            <Typography
                                                as="span"
                                                variant="small"
                                                className="font-normal"
                                            >
                                                Account Settings
                                            </Typography>
                                        </MenuItem>
                                    </Link>
                                    <div className="border-t border-gray-200 dark:border-gray-600"></div>
                                    <Link to="/logout">
                                        <MenuItem
                                            onClick={closeMenu}
                                            className="flex items-center gap-2 rounded"
                                        >
                                            <KeyIcon
                                                className="size-4"
                                                strokeWidth={2}
                                            />
                                            <Typography
                                                as="span"
                                                variant="small"
                                                className="font-normal"
                                            >
                                                Log Out
                                            </Typography>
                                        </MenuItem>
                                    </Link>
                                    {process.env.REACT_APP_INVITATION_ONLY && (
                                        <>
                                            <div className="border-t border-gray-200 dark:border-gray-600"></div>

                                            {/* <!-- Invitations (ALPHA) --> */}
                                            <div className="block px-4 pt-2 pb-0.5 text-xs text-gray-400 text-center">
                                                Invitations
                                            </div>

                                            <Link to="/user/invitations">
                                                <div className="text-center -my-0.5 px-3">
                                                    <div className="bg-gray-400 dark:bg-gray-900 w-full rounded-full text-xs py-0 leading-none h-[12px] text-center relative mb-0.5">
                                                        <div className="absolute z-20 w-full text-white">
                                                            {
                                                                user?.invited_count
                                                            }
                                                            /{max_invites}
                                                        </div>
                                                        {user && (
                                                            <div
                                                                className="absolute z-10 bg-brand-500 rounded-full text-xs leading-none h-[12px] text-center"
                                                                style={{
                                                                    width: `${(user.invited_count / max_invites) * 100}%`,
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    Invite New User
                                                </div>
                                            </Link>
                                        </>
                                    )}
                                </MenuList>
                            </Menu>
                        </>
                    ) : (
                        <>
                            <Link to="/login">
                                <Button
                                    variant="text"
                                    className="flex items-center gap-2 px-3 text-white md:text-gray-400"
                                >
                                    <KeyIcon className="size-5" />
                                    Login
                                </Button>
                            </Link>
                            <Link to="/register">
                                <Button
                                    variant="text"
                                    className="flex items-center gap-2 px-3 text-white md:text-gray-400"
                                >
                                    <UserPlusIcon className="size-5" />
                                    Register
                                </Button>
                            </Link>
                        </>
                    )}
                </div>
            </div>
            <div className="bg-white dark:bg-gray-800 shadow md:hidden">
                <div className="max-w-7xl mx-auto p-3 md:py-5 md:px-6 lg:px-8 flex flex-row justify-evenly">
                    <NavLink
                        to="/dashboard"
                        active={location.pathname.startsWith("/dashboard")}
                    >
                        <HomeIcon className="size-6" />
                    </NavLink>
                    <NavLink
                        to="/tracks"
                        active={location.pathname.startsWith("/tracks")}
                    >
                        <GlobeAltIcon className="size-6" />
                    </NavLink>
                    <NavLink
                        to="/events"
                        active={location.pathname.startsWith("/events")}
                    >
                        <CalendarDaysIcon className="size-6" />
                    </NavLink>
                </div>
            </div>
        </NavbarMT>
    );
}
