import TextLogo from "src/Components/Logos/TextLogo";

export default function Privacy() {
    return (
        <div className="font-sans text-gray-900 dark:text-gray-100 antialiased">
            <div className="pt-4 bg-gray-100 dark:bg-gray-900">
                <div className="min-h-screen flex flex-col items-center py-6 md:pt-0">
                    <div className="w-72">
                        <TextLogo
                            classes="text-brand-600"
                            secondaryClasses="text-brand-500"
                        />
                    </div>
                    <div className="w-full md:max-w-4xl mt-6 p-6 bg-white dark:bg-gray-800 shadow-md text-sm overflow-hidden md:rounded-md prose dark:prose-invert">
                        <h1 className="mb-0">Privacy Policy</h1>
                        <p className="my-0.5 text-gray-500">
                            Last Updated 13th July 2024
                        </p>
                        <p>
                            This privacy policy for Kart Social (&apos;
                            <strong>we</strong>&apos;, &apos;<strong>us</strong>
                            &apos;, or &apos;
                            <strong>our</strong>&apos;), a trading name of
                            Robert Trehy, describes how and why we might
                            collect, store, use, and/or share (&apos;
                            <strong>process</strong>
                            &apos;) your information when you use our services
                            (&apos;
                            <strong>Services</strong>&apos;), such as when you:
                        </p>
                        <ul>
                            <li>
                                Visit our website at{" "}
                                <a
                                    className="hover:text-brand-500"
                                    href="https://kartsocial.com"
                                >
                                    https://kartsocial.com
                                </a>
                                , or any website of ours that links to this
                                privacy notice.
                            </li>
                            <li>
                                Engage with us in other related ways, including
                                any sales, marketing, or events.
                            </li>
                        </ul>
                        <p>
                            <strong>Questions or concerns?</strong> Reading this
                            privacy policy will help you understand your privacy
                            rights and choices. If you do not agree with our
                            policies and practices, please do not use our
                            Services. If you still have any questions or
                            concerns, please contact us at{" "}
                            <a
                                className="hover:text-brand-500"
                                href="mailto:app@kartsocial.com"
                            >
                                app@kartsocial.com
                            </a>
                        </p>

                        <h2>Summary of Key Points</h2>
                        <p>
                            <em>
                                <strong>
                                    This summary provides keys points from our
                                    privacy policy, but you can find out more
                                    details about any of these topics by
                                    clicking the link following each key point
                                    or by using our{" "}
                                    <a
                                        className="hover:text-brand-500"
                                        href="#toc"
                                    >
                                        table of contents
                                    </a>{" "}
                                    below to find the section you are looking
                                    for.
                                </strong>
                            </em>
                        </p>
                        <p>
                            <strong>
                                What personal information do we process?
                            </strong>{" "}
                            When you visit, use, or navigate our Services, we
                            may process personal information depending on how
                            you interact with use and the Services, the choices
                            you make, and the products and features you use.
                            Learn more about{" "}
                            <a
                                className="hover:text-brand-500"
                                href="#1-1-personal-information-provided-by-you"
                            >
                                personal information you disclose to us
                            </a>
                            .
                        </p>
                        <p>
                            <strong>
                                Do we process any sensitive personal
                                information?
                            </strong>{" "}
                            We may process sensitive personal information when
                            necessary with your consent or as otherwise
                            permitted by applicable law. Learn more about{" "}
                            <a
                                className="hover:text-brand-500"
                                href="#1-2-sensitive-information"
                            >
                                sensitive information we process
                            </a>
                            .
                        </p>
                        <p>
                            <strong>
                                Do we receive any information from third
                                parties?
                            </strong>{" "}
                            We do not receive any inforamtion from third
                            parties.
                        </p>
                        <p>
                            <strong>How do we process your information?</strong>{" "}
                            We process your information to provide, improve, and
                            administer our Services, communicate with you, for
                            security and fraud prevention, and to comply with
                            law. We may also process your information for other
                            purposes with your consent. We process your
                            information only when we have a valid legal reason
                            to do so. Learn more about{" "}
                            <a
                                className="hover:text-brand-500"
                                href="#2-how-do-we-process-your-information"
                            >
                                how we process your information
                            </a>
                            .
                        </p>
                        <p>
                            <strong>
                                In what situation and with which types of
                                parties do we share personal inforamtion?
                            </strong>{" "}
                            We may share information in specific situations and
                            with specific categories of third parties. Learn
                            more about{" "}
                            <a
                                className="hover:text-brand-500"
                                href="#4-when-and-with-whom-do-we-share-your-personal-information"
                            >
                                when and with whom we share your personal
                                information
                            </a>
                            .
                        </p>
                        <p>
                            <strong>
                                How do we keep your information sage?
                            </strong>{" "}
                            We have organisational and technical processes and
                            procedures in places to protect your personal
                            information. However, no electronic transmission
                            over the internet or information storage technology
                            can be guaranteed to be 100% secure, so we cannot
                            promise or guarantee that hackers, cybercriminals,
                            or other unauthorised third parties will not be able
                            to defeat our security and improperly collect,
                            access, steal, or modify your information. Learn
                            more about{" "}
                            <a
                                className="hover:text-brand-500"
                                href="#9-how-do-we-keep-your-information-safe"
                            >
                                how we keep your information safe
                            </a>
                            .
                        </p>
                        <p>
                            <strong>What are you rights?</strong> Depending on
                            where you are located geographically, the applicable
                            privacy law may mean you have certain rights
                            regarding your personal information. Learn more
                            about{" "}
                            <a
                                className="hover:text-brand-500"
                                href="#10-what-are-your-privacy-rights"
                            >
                                your privacy rights
                            </a>
                            .
                        </p>
                        <p>
                            <strong>How you exercise your rights?</strong> The
                            easiest way to exercise your rights is be visiting{" "}
                            <a
                                className="hover:text-brand-500"
                                href="https://kartsocial.com/user/account"
                            >
                                https://kartsocial.com/user/account
                            </a>
                            , or by contacting us. We will consider and act upon
                            any request in accordance with applicable data
                            protection laws.
                        </p>
                        <p>
                            Want to learn more about what we do with any
                            information we collection?{" "}
                            <a className="hover:text-brand-500" href="#toc">
                                Review the privacy policy in full
                            </a>
                            .
                        </p>

                        <h2>Table of Contents</h2>
                        <ul>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#1-what-information-do-we-collect"
                                >
                                    1. What information do we collect?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#2-how-do-we-process-your-information"
                                >
                                    2. How do we process your information?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#3-what-legal-bases-do-we-rely-on-to-process-your-personal-information"
                                >
                                    3. What legal bases do we rely on to process
                                    your personal information?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#4-when-and-with-whom-do-we-share-your-personal-information"
                                >
                                    4. When and with whom do we share your
                                    personal information?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#5-do-we-use-cookies-and-other-tracking-technologies"
                                >
                                    5. Do we use cookies and other tracking
                                    technologies?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#6-how-do-we-handle-your-social-logins"
                                >
                                    6. How do we handle your social logins?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#7-is-your-information-transferred-internationally"
                                >
                                    7. Is your information transferred
                                    internationally?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#8-how-long-do-we-keep-your-information"
                                >
                                    8. How long do we keep your information?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#9-how-do-we-keep-your-information-safe"
                                >
                                    9. How do we keep your information safe?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#10-what-are-your-privacy-rights"
                                >
                                    10. What are your privacy rights?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#11-controls-for-do-not-track-features"
                                >
                                    11. Controls for do-not-track features
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#12-do-united-states-residents-have-specific-privacy-rights"
                                >
                                    12. Do United States residents have specific
                                    privacy rights?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#13-do-we-make-updates-to-this-notice"
                                >
                                    13. Do we make updates to this notice?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#14-how-can-you-contact-us-about-this-notice"
                                >
                                    14. How can you contact us about this
                                    notice?
                                </a>
                            </li>
                            <li>
                                <a
                                    className="hover:text-brand-500"
                                    href="#15-how-can-you-review-update-or-delete-the-data-we-collect-from-you"
                                >
                                    15. How can you review, update, or delete
                                    the data we collect from you?
                                </a>
                            </li>
                        </ul>
                        <h2 id="1-what-information-do-we-collect">
                            1. What information do we collect?
                        </h2>

                        <h3>Personal information you disclose to us</h3>
                        <p>
                            <em>
                                <strong>In Short:</strong> We collect personal
                                information that you provide to us.
                            </em>
                        </p>
                        <p>
                            We collect personal information that you voluntarily
                            provide to us when you register on the Services,
                            express an interest in obtaining information about
                            us or our products and Services, when you
                            participate in activities on the Services, or
                            otherwise when you contact us.
                        </p>
                        <p id="1-1-personal-information-provided-by-you">
                            {" "}
                            <strong>
                                Personal Information Provided by You.
                            </strong>{" "}
                            The personal information that we collect depends on
                            the context of your interactions with us and the
                            Services, the choices you make, and the products and
                            features you use. The personal information we
                            collect may include the following:
                        </p>
                        <ul>
                            <li>names</li>
                            <li>email addresses</li>
                            <li>usernames</li>
                            <li>passwords</li>
                            <li>contact preferences</li>
                            <li>contact or authentication data</li>
                        </ul>
                        <p id="1-2-sensitive-information">
                            {" "}
                            <strong>Sensitive Information.</strong> When
                            necessary, with your consent or as otherwise
                            permitted by applicable law, we process the
                            following categories of sensitive information:
                        </p>
                        <ul>
                            <li>health data</li>
                            <li>financial data</li>
                        </ul>
                        <p>
                            <strong>Social Media Login Data.</strong> We may
                            provide you with the option to register with us
                            using your existing social media account details,
                            like your Facebook, Twitter, or other social media
                            account. If you choose to register this way, we will
                            collect the inforamtion described in the section
                            called &apos;
                            <a
                                className="hover:text-brand-500"
                                href="#6-how-do-we-handle-your-social-logins"
                            >
                                How do we handle your social logins?
                            </a>
                            &apos; below.
                        </p>
                        <p>
                            All personal information that you provide to us must
                            be true, complete, and accurate, and you must notify
                            us of any changes to such personal information.
                        </p>

                        <h3>Information automatically collected</h3>
                        <p>
                            <em>
                                <strong>In Short:</strong> Some information -
                                such as your Internet Protocol (IP) address
                                and/or browser and device characteristics - is
                                collected automatically when you visit our
                                Services.
                            </em>
                        </p>
                        <p>
                            We automatically collect certain information when
                            you visit, use, or navigate the Services. This
                            information does not reveal your specific identity
                            (like your name or contact information) but may
                            include device and usage information, such as your
                            IP address, browser and device characteristics,
                            operating system, language preferences, referring
                            URLs, device name, country, location, information
                            about how and when you use our Services, and other
                            technical information. This information is primarily
                            needed to maintain the security and operation of our
                            Services, and for our internal analytics and
                            reporting purposes.
                        </p>
                        <p>
                            Like many businesses, we also collect information
                            through cookies and similar technologies.
                        </p>
                        <p>The information we collect includes:</p>
                        <ul>
                            <li>
                                <em>Log and Usage Data.</em> Log and usage data
                                is service-related, diagnostic, usage, and
                                performance information our servers
                                automatically collect when you access or use our
                                Services and which we record in log files.
                                Depending on how you interact with us, this log
                                data may include your IP address, device
                                information, browser type, and settings and
                                information about your activity in the Services
                                (such as the date/time stamps associated with
                                your usage, pages and files viewed, searches,
                                and other actions you take such as which
                                features you use), device event information
                                (such as system activity, error reports
                                (sometimes called &apos;crash dumps&apos;), and
                                hardware settings).
                            </li>
                            <li>
                                <em>Device Data.</em> We collect device data
                                such as information about your computer, phone,
                                tablet, or other device you use to access the
                                Services. Depending on the device used, this
                                device data may include information such as your
                                IP address (or proxy server), device and
                                application identification numbers, location,
                                browser type, hardware model, Internet service
                                provider and/or mobile carrier, operating
                                system, and system configuration information.
                            </li>
                            <li>
                                <em>Location Data.</em> We collect location data
                                such as information about your device&apos;s
                                location, which can be either precise or
                                imprecise. How much information we collect
                                depends on the type and settings of the device
                                your use to access the Services. For example, we
                                may use GPS and other technologies to collect
                                geolocation data that tells us your current
                                location (based on your IP address). You can opt
                                out of allowing us to collect this information
                                either by refusing access to teh information or
                                by disabling your Location setting on your
                                device. However, if you choose to opt out, you
                                may not be able to use certain aspects of the
                                Services.
                            </li>
                        </ul>
                        <h2 id="2-how-do-we-process-your-information">
                            2. How do we process your information?
                        </h2>
                        <p>
                            <em>
                                <strong>In Short:</strong> We process your
                                information to provide, improve, and administer
                                our Services, communicate with you, for security
                                and fraud prevention, and to comply with law. We
                                may also process your information for other
                                purposes with your consent.
                            </em>
                        </p>
                        <p>
                            <strong>
                                We process your personal information for a
                                variety of reasons, depending on how you
                                interact with our Services, including:
                            </strong>
                        </p>
                        <ul>
                            <li>
                                <strong>
                                    To facilitate account creation and
                                    authentication and otherwise manage user
                                    accounts.
                                </strong>{" "}
                                We may process your inforamtion so you can
                                create and log in to your account, as well as
                                keep your account in working order.
                            </li>
                            <li>
                                <strong>
                                    To deliver and facilitate delivery of
                                    services to the user.
                                </strong>{" "}
                                We may process your information to provide you
                                with the requested service.
                            </li>
                            <li>
                                <strong>
                                    To send administrative information to you.
                                </strong>{" "}
                                We may process your information to send you
                                details about our products and services, changes
                                to our terms and policies, and other similar
                                information.
                            </li>
                            <li>
                                <strong>
                                    To enable user-to-user communications.
                                </strong>{" "}
                                We may process your information if you choose to
                                use any of our offerings that allow for
                                communication with another user.
                            </li>
                            <li>
                                <strong>To request feedback.</strong> We may
                                process your information when necessary to
                                request feedback and to contact you about your
                                use of our Services.
                            </li>
                            <li>
                                <strong>To protect our Services.</strong> We may
                                process your information as part of our efforts
                                to keep our Services safe and secure, including
                                fraud monitoring and prevention.
                            </li>
                            <li>
                                <strong>To identify usage trends.</strong> We
                                may process your information about how you use
                                our Services to better understand how they are
                                being used so we can improve them.
                            </li>
                            <li>
                                <strong>
                                    To save or protect an individual&apos;s
                                    vital interest.
                                </strong>{" "}
                                We may process your information when necessary
                                to save or protect an individual&apos;s vital
                                interest, such as to prevent harm.
                            </li>
                        </ul>
                        <h2 id="3-what-legal-bases-do-we-rely-on-to-process-your-personal-information">
                            3. What legal bases do we rely on to process your
                            personal information?
                        </h2>
                        <p>
                            <em>
                                <strong>In Short:</strong> We only process your
                                personal information when we believe it is
                                necessary and we have a valid legal reason (i.e.
                                legal basis) to do so under applicable law, like
                                with your consent, to comply with laws, to
                                provide you with services to enter into or
                                fulfil our contractual obligations, to protect
                                your rights, or to fulfil our legitimate
                                business interests.
                            </em>
                        </p>
                        <p>
                            <strong>
                                If you are located in the EU or UK, this section
                                applies to you.
                            </strong>
                        </p>
                        <p>
                            The General Data Protection Regulation (GDPR) and UK
                            GDPR require us to explain the valid legal bases we
                            rely on in order to process your personal
                            information. As such, we may rely on the following
                            legal bases to process your personal information:
                        </p>
                        <ul>
                            <li>
                                <strong>Consent.</strong> We may process your
                                inforamtion if you have given us permission
                                (i.e. consent) to use your personal information
                                for a specific purpose. You can withdraw your
                                consent at any time. Learn more about{" "}
                                <a
                                    className="hover:text-brand-500"
                                    href="#10-1-withdrawing-your-consent"
                                >
                                    withdrawing your consent
                                </a>
                                .
                            </li>
                            <li>
                                <strong>Performance of a Contract.</strong> We
                                may process your information when we believe it
                                is necessary to fulfil our contractual
                                obligations to you, inlcuding providing our
                                Services or at your request prior to entering
                                into a contract with you.
                            </li>
                            <li>
                                <strong>Legitimate Interests.</strong> We may
                                process your information when we believe it is
                                reasonably necessary to achieve our legitimate
                                business interests and those interests do not
                                outweigh your interests and fundamental rights
                                and freedoms. For example, we may process your
                                personal information for some of the purposes
                                described in order to:
                                <ul>
                                    <li>
                                        Analyse how our Services are used so we
                                        can improve them to engage and retain
                                        users.
                                    </li>
                                    <li>
                                        Diagnose problems and/or prevent
                                        fraudulant activities.
                                    </li>
                                    <li>
                                        Understand how our users use our
                                        products and services so we can improve
                                        user experience.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Legal Obligations.</strong> We may
                                process your information where we believe it is
                                necessary for compliance with our legal
                                obligations, such as to cooperate with a law
                                enforcement body or regulatory agency, exercise
                                or defend our legal rights, or disclose your
                                information as evidence in litigation in which
                                we are involved.
                            </li>
                            <li>
                                <strong>Vital Interests.</strong> We may process
                                your information where we beleive it is
                                necessary to protect your vital interests or the
                                vital interests of a third party, such as
                                situations involving potential threats to the
                                safety of any person.
                            </li>
                        </ul>
                        <p>
                            <strong>
                                If you are located in Canada, this section
                                applies to you.
                            </strong>
                        </p>
                        <p>
                            We may process your information if you have given us
                            specific permission (i.e. express consent) to use
                            your personal information for a specific purpose, or
                            in situation shwere your permission can be inferred
                            (i.e. implied consent). You can{" "}
                            <a
                                className="hover:text-brand-500"
                                href="#10-1-withdrawing-your-consent"
                            >
                                withdraw your consent
                            </a>{" "}
                            at any time.
                        </p>
                        <p>
                            In some exceptional cases, we may be legally
                            permitted under applicable laws to process your
                            information without your consent, including, for
                            example:
                        </p>
                        <ul>
                            <li>
                                If collection is clearly in the interests of an
                                individual and consent cannot be obtained in a
                                timely way.
                            </li>
                            <li>
                                For investigations and fraud detection and
                                prevention.
                            </li>
                            <li>
                                For business transactions provided certain
                                conditions are met.
                            </li>
                            <li>
                                If it is contained in a witness statement and
                                the collection is necessary to assess, process,
                                or settle an insurance claim.
                            </li>
                            <li>
                                For identifying injured, ill, or deceases
                                persons and communicating with next of kin.
                            </li>
                            <li>
                                If we have reasonable grounds to believe an
                                individual has been, is, or may be a victim of
                                financial abuse.
                            </li>
                            <li>
                                If it is reasonable to expect collection and use
                                with consent would compromise the availability
                                or the accuracy of the information and the
                                collection is reasonable for purposes related to
                                investigating a breach of an agreement or a
                                contravention of the laws of Canada or a
                                province.
                            </li>
                            <li>
                                If disclosure is required to comply with a
                                subpoena, warrant, court order, or rules of the
                                court relating to production of records.
                            </li>
                            <li>
                                If it was produced by an individual in the
                                course of their employement, business, or
                                profession and the collection is consistent with
                                the purposes for which the information was
                                produced.
                            </li>
                            <li>
                                If the collection is solely for journalistic,
                                artistic, or literary purposes.
                            </li>
                            <li>
                                If the information is publicly available and is
                                specified by the regulations.
                            </li>
                        </ul>
                        <h2 id="4-when-and-with-whom-do-we-share-your-personal-information">
                            4. When and with whom do we share your personal
                            information?
                        </h2>
                        <p>
                            <em>
                                <strong>In Short:</strong> We may share
                                information in specific situations described in
                                this section and/or with the following
                                categories of third parties.
                            </em>
                        </p>
                        <p>
                            <strong>
                                Vendors, Consultants, and Other Third-Party
                                Service Providers.
                            </strong>{" "}
                            We may share data with third-party vendors, service
                            providers, contractors, or agents (&apos;
                            <strong>third parties</strong>&apos;) who perform
                            services fior us or on our behalf and require access
                            to such information to do that work. We have
                            contracts in place with our third partieis, which
                            are designed to help safeguard your personal
                            information. This means that they cannot do anything
                            with your personal information unless we have
                            instructed them to do it. They will also not share
                            your personal information with any organisation
                            apart from us. They aslo commit to protect the data
                            they hold on our behalf and to retain it for the
                            period we instruct. The categories of third parties
                            we may share personal information with are as
                            follows:
                        </p>
                        <ul>
                            <li>Data Analytics Services</li>
                            <li>Product Engineering &amp; Design Tools</li>
                        </ul>
                        <p>
                            We also may need to share your personal information
                            in the following situations:
                        </p>
                        <ul>
                            <li>
                                <strong>Business Transfers.</strong> We may
                                share of transfer your information in connection
                                with, or during negotiations of, any merger,
                                sale of company assets, financing, or
                                acquisition of all or a portion of our business
                                to another company.
                            </li>
                            <li>
                                <strong>
                                    When we use Google Maps Platform APIs.
                                </strong>{" "}
                                We may share your information with certain
                                Google Maps Platform APIs (e.g. Google Maps API,
                                Places API). We obtain and store on your device
                                (&apos;cache&apos;) your location. You may
                                revoke your consent anytime by contact us at the
                                contact details provided at the end of this
                                document.
                            </li>
                            <li>
                                <strong>Other Users.</strong> When you share
                                your personal information (for example, by
                                positing comments, contributions, or other
                                content to the Services) or otherwise interact
                                with public areas of the Services, such personal
                                information may be viewed by all users and may
                                be publicly made available outside the Services
                                in perpetuity. If you interact with other users
                                of our Services and register for our Services
                                through a social network (such as Facebook),
                                your contacts on the social network will see
                                your name, profile photo, and descriptions of
                                your activity. Similarly, other users will be
                                able to view descriptions of your activity,
                                communicate with you within our Services, and
                                view your profile.
                            </li>
                        </ul>
                        <h2 id="5-do-we-use-cookies-and-other-tracking-technologies">
                            5. Do we use cookies and other tracking
                            technologies?
                        </h2>
                        <p>
                            <em>
                                <strong>In Short:</strong> We may use cookies
                                and other tracking technologies to collect and
                                store your information.
                            </em>
                        </p>
                        <p>
                            We may use cookies and similar tracking technologies
                            (like web beacons and pixels) to access or store
                            information. Specific information about how we use
                            such technologies and how you can refuse certain
                            cookies is set out in our Cookie Policy.
                        </p>
                        <h2 id="6-how-do-we-handle-your-social-logins">
                            6. How do we handle your social logins?
                        </h2>
                        <p>
                            <em>
                                <strong>In Short:</strong> If you choose to
                                register or log in to our Services using a
                                social media account, we may have access to
                                certain information about you.
                            </em>
                        </p>
                        <p>
                            Our Services offer you the ability to register and
                            log in using your third-party social mediate account
                            details (like your Facebook or Twitter logins).
                            Where you choose to do this, we will receive certain
                            profile information about you from your social media
                            provider. This profile information we receive may
                            vary depending on the social media provider
                            concerned, but will often include your name, email
                            address, friends list, and profile picture, as well
                            as other information you choose to make public on
                            such a social media platform.
                        </p>
                        <p>
                            We will use the information we recieve only for the
                            purposes that are described in this privacy policy
                            or that are otherwise made clear to you on the
                            relevant Services. Please note that we do not
                            control, and are not responsible for, other uses of
                            your personal information by your third-party social
                            media provider. We recommend that you review their
                            privacy notice to understand how they collect, use,
                            and share your personal information, and how you can
                            set your privacy preferences on their sites and
                            apps.
                        </p>
                        <h2 id="7-is-your-information-transferred-internationally">
                            7. Is your information transferred internationally?
                        </h2>
                        <p>
                            <em>
                                <strong>In Short:</strong> We may transfer,
                                store, and process your information in countries
                                other than your own.
                            </em>
                        </p>
                        <p>
                            Our servers are located in the United States, and
                            Germany. If you are accessing our Services from
                            outside the United States, and Germany, please be
                            aware that your information may be transferred to,
                            stored, and processed by us in our facilities and by
                            those third parties with whom we may share your
                            personal infmration (see &apos;
                            <a
                                className="hover:text-brand-500"
                                href="#4-when-and-with-whom-do-we-share-your-personal-information"
                            >
                                When and with whom do we share your personal
                                information?
                            </a>
                            &apos; above), in the United States, and other
                            countries.
                        </p>
                        <p>
                            If you are a resident in the European Economic Area
                            (EEA), United Kingdom (UK), or Switzerland, then
                            these countries may not necessarily have data
                            protection laws or other similar laws as
                            comprehensive as those in your country. However, we
                            will take all necessary measure to protect your
                            personal information in accordance with this privacy
                            policy and applicable law.
                        </p>
                        <p>
                            European Commission&apos;s Standard Contractual
                            Clauses:
                        </p>
                        <p>
                            We have implemented measures to protect your
                            personal information, including by using the
                            European Commission&apos;s Standard Contractual
                            Clauses for transfers of personal information
                            between our group companies and between us and our
                            third-party providers. These clauses require all
                            recipients to protect all personal information that
                            they process originating from the EEA or UK in
                            accordance with European data protection laws and
                            regulations. Our Standard Contractual Clauses can be
                            provided upon request. We have implemented similar
                            appropriate safeguards with our third-party service
                            providers and partners and further details can be
                            provided upon request.
                        </p>
                        <h2 id="8-how-long-do-we-keep-your-information">
                            8. How long do we keep your information?
                        </h2>
                        <p>
                            <em>
                                <strong>In Short:</strong> We keep your
                                information as long as necessary to fulfil the
                                purposes outlined in this privacy policy unless
                                otherwise required by law.
                            </em>
                        </p>
                        <p>
                            We will only keep your personal information for as
                            long as it is necessary for the purposes set out in
                            this privacy notice, unless a longer retention
                            period is required or permitted by law (such as tax,
                            accounting, or other legal reasons). No purpose in
                            this notice will require us keeping your personal
                            information for longer than the period of time in
                            which users have an account with us.
                        </p>
                        <p>
                            When we have no ongoing legitimate business need to
                            process your personal information, we will either
                            delete or anonymise such information, or, if this is
                            not possible (for example, because your personal
                            information has been stored in backup archives),
                            then we will securely store your personal
                            information and isolate it from any further
                            processing until deletion is possible.
                        </p>
                        <h2 id="9-how-do-we-keep-your-information-safe">
                            9. How do we keep your information safe?
                        </h2>
                        <p>
                            <em>
                                <strong>In Short:</strong> We aim to protect
                                your personal information through a system of
                                organisational and technical security measures.
                            </em>
                        </p>
                        <p>
                            We have implemented appropriate and reasonable
                            technical and organisational security measures
                            designed to protect the security of any personal
                            information we process. However, despite our
                            safeguards and efforts to secure your information,
                            no electronic transmission over the Internet or
                            information storage technology can be guaranteed to
                            be 100% secure, so we cannot promise or guarantee
                            that hackers, cybercriminals, or other unauthorised
                            third parties will not be able to defeat our
                            security and improperly collect, access, steal, or
                            modify your information. Although we will do our
                            best to protect your personal information,
                            transmission of personal information to and from our
                            Services is at your own risk. You should only access
                            the Services within a secure environment.
                        </p>
                        <h2 id="10-what-are-your-privacy-rights">
                            10. What are your privacy rights?
                        </h2>
                        <p>
                            <em>
                                <strong>In Short:</strong> In some regions, such
                                as the European Economic Area (EEA), United
                                Kingdom (UK), Switzerland, and Canada, you have
                                rights that allow you greater access to and
                                control over your personal information. You may
                                review, change, or terminate your account at any
                                time.
                            </em>
                        </p>
                        <p>
                            In some regions (like the EEA, UK, Switzerland, and
                            Canada), you have certain rights under applicable
                            data protection laws. These may include the right
                            (1) to request access and obtain a copy of your
                            personal information, (2) to request rectification
                            or erasure; (3) to restrict the processing of your
                            personal infomration; (4) if applicable, to data
                            portability; and (5) not to be subject to automated
                            decision-making. In certain circumstances, you may
                            also have the right to object to the processing of
                            your personal information. You can make sure a
                            request by contacting us by using the contact
                            details provided in the section &apos;
                            <a
                                className="hover:text-brand-500"
                                href="#14-how-can-you-contact-us-about-this-notice"
                            >
                                How can you contact us about this notice?
                            </a>
                            &apos; below.
                        </p>
                        <p>
                            We will consider and act upon any request in
                            accordance with applicable data protection laws.
                        </p>
                        <p>
                            If you are loged in the EEA or UK and you believe we
                            are unlawfully processing your personal information,
                            you also have the right to complain to your{" "}
                            <a
                                className="hover:text-brand-500"
                                href="https://ec.europa.eu/newsroom/article29/items/612080"
                            >
                                Member State data protection authority
                            </a>{" "}
                            or{" "}
                            <a
                                className="hover:text-brand-500"
                                href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                            >
                                UK data protection authority
                            </a>
                            .
                        </p>
                        <p>
                            If you are located in Switzerland, you may contact
                            the{" "}
                            <a
                                className="hover:text-brand-500"
                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                            >
                                Federal Data Protection and Information
                                Comissioner
                            </a>
                            .
                        </p>
                        <p id="10-1-withdrawing-your-consent">
                            <strong>Withdrawing your consent:</strong> If we are
                            relying on your consent to process your personal
                            information, which may be express and/or implied
                            consent depending on the applicable law, you have
                            the right to withdraw your consent at any time. You
                            can withdraw your consent at any time by contact us
                            by using the contact details provided in the section
                            &apos;
                            <a
                                className="hover:text-brand-500"
                                href="#14-how-can-you-contact-us-about-this-notice"
                            >
                                How can you contact us about this notice?
                            </a>
                            &apos; below.
                        </p>
                        <p>
                            However, please note that this will not affect the
                            lawfulness of the processing before its withdrawal
                            nor, when applicable law allows, will it affect the
                            processing of your personal information conducted in
                            reliance on lawful processing grounds other than
                            consent.
                        </p>

                        <h3>Account Information</h3>
                        <p>
                            If you would at any time like to review or change
                            the information in your account or terminate your
                            account, you can:
                        </p>
                        <ul>
                            <li>
                                Log in to your account settings and update your
                                user account.
                            </li>
                        </ul>
                        <p>
                            Upon your request to terminate your account, we will
                            deactivate or delete your account and information
                            from our active databases. However, we may retain
                            some information in our files to prevent fraud,
                            troubleshoot problems, assist with any
                            investigations, enforce our legal terms and/or
                            comply with applicable legal requirements.
                        </p>
                        <p>
                            <strong>Cookies and similar technologies:</strong>{" "}
                            Most web browsers are set to accept cookies by
                            default. If you prefer, you can usually choose to
                            set your browser to remove cookies and to reject
                            cookies. If you choose to remove cookies or reject
                            cookies, this could affect certain features or
                            services of our Services.
                        </p>
                        <p>
                            If you have questions or comments about your privacy
                            rights, you may email us at{" "}
                            <a
                                className="hover:text-brand-500"
                                href="mailto:app@kartsocial.com"
                            >
                                app@kartsocial.com
                            </a>
                            .
                        </p>
                        <h2 id="11-controls-for-do-not-track-features">
                            11. Controls for do-not-track features
                        </h2>
                        <p>
                            Most web browser and some mobile operating systems
                            and mobile applications include a Do-No-Track
                            (&apos;DNT&apos;) feature or setting you can
                            activate to signal your privacy preference not to
                            have data about yoru online browsing activities
                            monitored and collected. At this stage no uniform
                            technology standard for recognising and implementing
                            DNT signals has been finalised. As such, we do not
                            currently respond to DNT browser signals or any
                            other mechanism that automatically communicates your
                            choice not to be track online. If a standard for
                            online tracking is adopted that we must follow in
                            the future, we will inform you about that practice
                            in a revised version of this privacy notice.
                        </p>
                        <h2 id="12-do-united-states-residents-have-specific-privacy-rights">
                            12. Do United States residents have specific privacy
                            rights?
                        </h2>
                        <p>
                            <strong>
                                What categories of personal inforamtion do we
                                collect?
                            </strong>
                        </p>
                        <p>
                            We have collected the following categories of
                            personal information in the past twelve (12) months:
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Examples</th>
                                    <th>Collected</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>A. Identifiers.</td>
                                    <td>
                                        Contact details, such as real name,
                                        alias, postal address, telephone or
                                        mobile contact number, unique personal
                                        identifier, online identifier, Internet
                                        Protocol address, email address, and
                                        account name,
                                    </td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td>
                                        B. Protected classifications
                                        characteristics under state or federal
                                        law.
                                    </td>
                                    <td>Gender and date of birth.</td>
                                    <td>YES</td>
                                </tr>
                                <tr>
                                    <td>C. Commerical information.</td>
                                    <td>
                                        Transaction information, purchase
                                        history, financial details, and payment
                                        information.
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>D. Biometric information.</td>
                                    <td>Fingerprints and voiceprints.</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>
                                        E. Internet or other similar network
                                        activity.
                                    </td>
                                    <td>
                                        Browsing history, search history, online
                                        behaviour, interest data, and
                                        interactions with our and other
                                        websites, applications, systems, and
                                        advertisements.
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>F. Geolocation data.</td>
                                    <td>Device location.</td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>
                                        G. Audio, electronic, visual, thermal,
                                        olfactory, or similar information.
                                    </td>
                                    <td>
                                        Images and audio, video or call
                                        recordings create in connection with our
                                        business activities.
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>
                                        H. Professional or employment-related
                                        information.
                                    </td>
                                    <td>
                                        Business contact details in order to
                                        provide you our Services at a business
                                        level or job title, work history, and
                                        professional qualifications if you apply
                                        for a job with us.
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>I. Education information.</td>
                                    <td>
                                        Student records and directory
                                        information.
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>
                                        J. Inferences drawn from collected
                                        personal inforamtion.
                                    </td>
                                    <td>
                                        Inferences drawn from any of the
                                        collected personal information listed
                                        above to create a profile or summary
                                        about, for example, an individual&apos;s
                                        preferences and characteristics.
                                    </td>
                                    <td>NO</td>
                                </tr>
                                <tr>
                                    <td>K. Sensitive personal information</td>
                                    <td></td>
                                    <td>NO</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            We will use and retain the collected personal
                            information as needed to provide the Services or
                            for:
                        </p>
                        <ul>
                            <li>
                                Category A - As long as the user has an account
                                with us.
                            </li>
                            <li>
                                Category B - As long as the user has an account
                                with us.
                            </li>
                        </ul>
                        <p>
                            We may also collect other personal information
                            outside of these categories through instances where
                            you interact with us in person, online, or by phone
                            or mail in the context of:
                        </p>
                        <ul>
                            <li>
                                Receiving help through our customer support
                                channels;
                            </li>
                            <li>
                                Participation in customer surveys or contests;
                                and
                            </li>
                            <li>
                                Facilitiation in the delivery of our Services
                                and to respond to your inquiries.
                            </li>
                        </ul>
                        <p>
                            <strong>
                                How do we use and share your personal
                                information?
                            </strong>
                            Learn about how we use your personal information in
                            the section, &apos;
                            <a
                                className="hover:text-brand-500"
                                href="#2-how-do-we-process-your-information"
                            >
                                How do we process your information?
                            </a>
                            &apos;.
                        </p>
                        <p>
                            <strong>
                                Will your information be shared with anyone
                                else?
                            </strong>
                        </p>
                        <p>
                            We may disclose your personal information with our
                            service providers pursuant to a written contact
                            between us and each service provider. learn more
                            about who we disclose personal information to in the
                            section, &apos;
                            <a
                                className="hover:text-brand-500"
                                href="#4-when-and-with-whom-do-we-share-your-personal-information"
                            >
                                When and with whom do we share your personal
                                information?
                            </a>
                            &apos;.
                        </p>
                        <p>
                            We may use your personal information for our own
                            business purposes, such as for undertaking internal
                            research for tecnological development and
                            demonstration. This is not considered to be
                            &apos;selling&apos; of your personal information.
                        </p>
                        <p>
                            We have not sold or shared any personal information
                            to third parties for a business or commercial
                            purpose in the preceding twelve (12) months. We have
                            disclosed the following categories of personal
                            information to third parties for a buesiness or
                            commercial purpose in the preceding twelve (12)
                            months:
                        </p>
                        <ul>
                            <li>No personal information shared.</li>
                        </ul>
                        <p>
                            The categories of third parties to whom we disclosed
                            personal information for a business or commercial
                            purpose can be found under &apos;
                            <a
                                className="hover:text-brand-500"
                                href="#4-when-and-with-whom-do-we-share-your-personal-information"
                            >
                                When and with whom do we share your personal
                                information?
                            </a>
                            &apos;.
                        </p>
                        <h2 id="13-do-we-make-updates-to-this-notice">
                            13. Do we make updates to this notice?
                        </h2>
                        <p>
                            <em>
                                <strong>In Short:</strong> Yes, will update this
                                policy as necessary to stay compliant with
                                relevant laws.
                            </em>
                        </p>
                        <p>
                            We may update this privacy policy from time to time.
                            This update version will be indicated by an updated
                            &apos;Revised&apos; date and the updated version
                            will be effective as soon as it is accessible. If we
                            make material changes to this privacy policy, we may
                            notify you either by prominently posting a notice of
                            such changes or by directly sending you a
                            notification. We encourage you to review this
                            privacy notice frequently to be informed of how we
                            are protecting your information.
                        </p>
                        <h2 id="14-how-can-you-contact-us-about-this-notice">
                            14. How can you contact us about this notice?
                        </h2>
                        <p>
                            If you have questions or comments about this notice,
                            you may email us at{" "}
                            <a
                                className="hover:text-brand-500"
                                href="mailto:app@kartsocial.com"
                            >
                                app@kartsocial.com
                            </a>
                            .
                        </p>
                        <h2 id="15-how-can-you-review-update-or-delete-the-data-we-collect-from-you">
                            15. How can you review, update, or delete the data
                            we collect from you?
                        </h2>
                        <p>
                            Based on the applicable laws of your country, you
                            may have the right to request access to the personal
                            information we collect from you, change that
                            information, or delete it. To request to review,
                            update, or delete your personal information, please
                            visit:{" "}
                            <a
                                className="hover:text-brand-500"
                                href="https://kartsocial.com/user/account"
                            >
                                https://kartsocial.com/user/account
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
