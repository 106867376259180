import moment from "moment";
import nprogress from "nprogress";
import { useEffect } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { useSanctum } from "react-sanctum";
import { PermissionProvider } from "src/Providers/Authentication";

import Navbar from "../Components/Navigation/Navbar";
import loadMomentLocale from "../Helpers/locale-loader";

export function AppLayout() {
    const { user } = useSanctum();

    const navigation = useNavigation();

    useEffect(() => {
        if (
            navigation.state === "loading" ||
            navigation.state === "submitting"
        ) {
            nprogress.inc();
        }

        if (navigation.state === "idle") {
            nprogress.done();
        }
    }, [navigation.state]);

    useEffect(() => {
        // Configure Moment to use user's locale
        let locale = user?.region || window.navigator.language.toLowerCase();
        loadMomentLocale(locale).then(() => {
            moment.locale(locale);
        });
    }, []);

    return (
        <PermissionProvider>
            <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
                <Navbar />
                {/* Banner */}

                <main className="container mx-auto flex-grow flex-shrink basis-0 pb-5">
                    <Outlet />
                </main>

                {/* FABHelp */}
            </div>
        </PermissionProvider>
    );
}
