import {
    Dialog,
    DialogPanel,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { IconButton } from "@material-tailwind/react";
import axios from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import { ApiUrl } from "src/Helpers/API_URL";
import { Track, User } from "src/types";

import ResultItemPerson from "./ResultItems/ResultItemPerson";
import ResultItemTrack from "./ResultItems/ResultItemTrack";

let controller: null | AbortController = null;

interface Results {
    tracks: Array<Track>;
    people: Array<User>;
}

let keys = {
    ctrl: false,
    slash: false,
};

export default function AppSearch() {
    const headerInputRef = useRef<HTMLInputElement | null>(null);
    const modalInputRef = useRef<HTMLInputElement | null>(null);
    const [term, setTerm] = useState<string>("");
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [results, setResults] = useState<Results | undefined>();

    useEffect(() => {
        window.addEventListener("keydown", function (e) {
            if (e.key === "Control") {
                keys.ctrl = true;
            }
            if (e.key === "/") {
                keys.slash = true;
            }

            if (keys.slash && keys.ctrl) {
                headerInputFocus();
            }
        });

        window.addEventListener("keyup", function (e) {
            if (e.key === "Control") {
                keys.ctrl = false;
            }
            if (e.key === "/") {
                keys.slash = false;
            }
        });
    }, [window]);

    const onKeyUp = async () => {
        if (term === "") {
            setResults(undefined);
        }

        if (controller !== null) {
            controller.abort();
        }

        if (term.length > 1) {
            controller = new AbortController();
            await axios
                .post(
                    ApiUrl("search"),
                    {
                        term: term,
                    },
                    {
                        signal: controller.signal,
                    },
                )
                .then((json) => {
                    setResults(json.data);
                })
                .catch((error) => {
                    if (error.message !== "canceled") {
                        console.error(error);
                    }
                });
        }
    };

    const headerInputFocus = () => {
        setIsFocus(true);
        modalInputRef.current?.focus();
    };

    const modalClose = () => {
        setIsFocus(false);
        setTerm("");
        setResults(undefined);
        headerInputRef.current?.blur();
    };

    return (
        <>
            {/* Mobile Search Icon */}
            <div className="md:hidden">
                <IconButton variant="text" onClick={headerInputFocus}>
                    <svg
                        className="flex-shrink-0 size-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <circle cx="11" cy="11" r="8" />
                        <path d="m21 21-4.3-4.3" />
                    </svg>
                    <span className="sr-only">Search</span>
                </IconButton>
            </div>

            {/* Search Bar */}
            <div className="hidden mx-auto md:block">
                <label htmlFor="icon" className="sr-only">
                    Search
                </label>
                <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-4">
                        <svg
                            className="flex-shrink-0 size-4 text-gray-400 dark:text-neutral-500"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <circle cx="11" cy="11" r="8" />
                            <path d="m21 21-4.3-4.3" />
                        </svg>
                    </div>
                    <input
                        type="text"
                        id="icon"
                        name="icon"
                        className="py-2 px-4 ps-11 pe-20 block border w-56 rounded-lg text-sm bg-transparent text-gray-600 border-gray-400 dark:border-gray-500 focus:border-gray-400 dark:docus:border-gray-500 placeholder:text-gray-400 focus:ring-0"
                        placeholder="Search"
                        onClick={headerInputFocus}
                    />
                    <div className="absolute inset-y-0 end-0 flex items-center pointer-events-none z-20 pe-4">
                        <span className="text-gray-400 dark:text-neutral-500">
                            Ctrl + /
                        </span>
                    </div>
                </div>
            </div>

            <Transition show={isFocus} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-[500]"
                    onClose={modalClose}
                >
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-300 bg-opacity-50 transition-opacity backdrop-blur-sm" />
                    </TransitionChild>

                    <div className="fixed inset-4 md:inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center text-center md:items-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                                enterTo="opacity-100 translate-y-0 md:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            >
                                <DialogPanel className="relative flex flex-col transform rounded-lg bg-white dark:bg-gray-900 text-left shadow-xl transition-all w-full max-w-full md:my-8 md:w-full md:max-w-lg">
                                    <div className="relative">
                                        <div className="absolute flex items-center z-20 ps-3 start-0 top-0 bottom-0 pointer-events-none">
                                            <MagnifyingGlassIcon className="size-4 dark:text-neutral-400" />
                                        </div>
                                        <input
                                            type="text"
                                            value={term}
                                            onChange={(e) =>
                                                setTerm(e.currentTarget.value)
                                            }
                                            onKeyUp={onKeyUp}
                                            ref={modalInputRef}
                                            className="text-sm ps-10 p-3 bg-white border-0 border-b border-gray-200 focus:border-gray-200 dark:border-neutral-700 dark:focus:border-neutral-700 rounded-t-lg w-full focus:ring-0 dark:bg-neutral-800 dark:text-neutral-400 dark:placeholder:text-neutral-400"
                                            placeholder="Search..."
                                        />
                                    </div>
                                    <div className="overflow-y-auto overflow-hidden h-[500px] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                                        {results && (
                                            <>
                                                <div className="block text-xs text-gray-500 m-3 mb-1 dark:text-neutral-500 dark:border-neutral-700 --exclude-accessibility">
                                                    Tracks
                                                </div>
                                                {results?.tracks.length > 0 ? (
                                                    results.tracks.map(
                                                        (
                                                            result: any,
                                                            i: number,
                                                        ) => (
                                                            <ResultItemTrack
                                                                result={result}
                                                                key={i}
                                                            />
                                                        ),
                                                    )
                                                ) : (
                                                    <div className="text-sm text-gray-500 mx-5">
                                                        No results found
                                                    </div>
                                                )}
                                                <div className="block text-xs text-gray-500 m-3 mb-1 dark:text-neutral-500 dark:border-neutral-700 --exclude-accessibility">
                                                    People
                                                </div>
                                                {results?.people.length > 0 ? (
                                                    results.people.map(
                                                        (
                                                            result: User,
                                                            i: number,
                                                        ) => (
                                                            <ResultItemPerson
                                                                result={result}
                                                                key={i}
                                                            />
                                                        ),
                                                    )
                                                ) : (
                                                    <div className="text-sm text-gray-500 mx-5">
                                                        No results found
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
