import { ThemeProvider } from "@material-tailwind/react";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import { Sanctum } from "react-sanctum";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MTTheme } from "./Theme/MTTheme";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "applicaton/json";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const sanctumConfig = {
    apiUrl: `${process.env.REACT_APP_API_BASE}`,
    csrfCookieRoute: "sanctum/csrf-cookie",
    signInRoute: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_API_VERSION}/login`,
    signOutRoute: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_API_VERSION}/logout`,
    userObjectRoute: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_API_VERSION}/user`,
    twoFactorChallengeRoute: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_API_VERSION}/two-factor-challenge`,
    usernameKey: "username",
    axiosInstance: axios,
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <ThemeProvider value={MTTheme}>
            <Sanctum config={sanctumConfig}>
                <App />
            </Sanctum>
        </ThemeProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
