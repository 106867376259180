import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { Button } from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";

export default function ServerError() {
    const navigate = useNavigate();

    return (
        <div className="container flex items-center min-h-screen px-6 py-12 mx-auto">
            <div>
                <p className="text-sm font-medium text-brand-500">500 error</p>
                <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">
                    Something went wrong!
                </h1>
                <p className="mt-4 text-gray-500">
                    Sorry, the action performed didn&apos;t go as expected.
                    We&apos;ve been told about it, so we&apos;ll check from our
                    end!
                </p>

                <div className="flex items-center mt-6 gap-x-3">
                    <Button
                        variant="outlined"
                        color="black"
                        onClick={() => navigate(-1)}
                        className="flex items-center gap-x-2"
                    >
                        <ArrowLeftIcon className="size-4" />
                        <span>Go back</span>
                    </Button>
                    <Button>
                        <Link to="/">Take me home</Link>
                    </Button>
                </div>
            </div>
        </div>
    );
}
