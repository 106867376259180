import classNames from "classnames";

interface Props {
    classes?: string;
    secondaryClasses?: string;
}

export default function InitialsLogo({ classes, secondaryClasses }: Props) {
    return (
        <h1 className={classNames("brand-font", classes)}>
            K<span className={secondaryClasses}>S</span>
        </h1>
    );
}
