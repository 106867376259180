import {
    IconButton,
    Menu,
    MenuHandler,
    MenuItem,
    MenuList,
} from "@material-tailwind/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSanctum } from "react-sanctum";
import { ApiUrl } from "src/Helpers/API_URL";
import { onMessageListener, requestForToken } from "src/Helpers/FirebaseApp";
import { Notification as NotificationType } from "src/types";

import NotificationItem from "./NotificationItem";
import NotificationsNavIcon from "./NotificationsNavIcon";

export default function Notifications() {
    const { user } = useSanctum();

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const [notifications, setNotifications] = useState<Array<NotificationType>>(
        user?.notifications || [],
    );
    const [unreadCount, setUnReadCount] = useState<number>(
        user?.unread_notifications || 0,
    );

    useEffect(() => {
        requestForToken();

        onMessageListener()
            .then(async () => {
                await axios
                    .get(ApiUrl("user/notifications"))
                    .then((json) => {
                        setNotifications(json.data.notifications);
                        setUnReadCount(json.data.unread_notifications);
                    })
                    .catch((err) => {
                        if (
                            err.response.status &&
                            err.response.status !== 422
                        ) {
                            throw new Response(err.response.message || "", {
                                status: err.response.status,
                            });
                        }
                    });
            })
            .catch((err) => {
                if (err.response.status && err.response.status !== 422) {
                    throw new Response(err.response.message || "", {
                        status: err.response.status,
                    });
                }
            });
    }, []);

    if (!user) {
        return null;
    }

    return (
        <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
            <MenuHandler>
                <IconButton variant="text">
                    <NotificationsNavIcon unread={unreadCount} />
                </IconButton>
            </MenuHandler>
            <MenuList className="w-full md:w-96 flex flex-col gap-1 p-1">
                {notifications.map((notification: NotificationType) => (
                    <MenuItem
                        key={notification.id}
                        className={`p-2 ${notification.read_at ? "" : "bg-gray-50"}`}
                    >
                        <NotificationItem notification={notification} />
                    </MenuItem>
                ))}
                <MenuItem className="p-2">
                    <Link
                        to="/notifications"
                        className="block text-center text-brand-600 hover:text-brand-500 dark:text-brand-400 dark:hover:text-brand-500"
                    >
                        View All Notifications
                    </Link>
                </MenuItem>
            </MenuList>
        </Menu>
    );
}
