import { MapPinIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Track } from "src/types";

interface Props {
    result: Track;
}

export default function ResultItemTrack({ result }: Props) {
    return (
        <Link
            to={`/tracks/${result.slug}`}
            className="py-2 px-3 group hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer flex flex-row gap-x-3 items-center w-full"
        >
            <div className="w-10 h-10 min-w-10 bg-brand-200 dark:bg-brand-800 rounded-md flex items-center">
                <MapPinIcon className="w-6 h-6 text-brand-800 dark:text-brand-200 mx-auto" />
            </div>
            <div className="flex flex-col overflow-hidden">
                <div className="font-semibold group-hover:text-brand-500 truncate">
                    {result.name}
                </div>
                <div className="text-xs truncate">
                    {result.address_1} {result.address_2} {result.address_3}{" "}
                    {result.town} {result.county} {result.postal_code}
                </div>
            </div>
        </Link>
    );
}
