import "./App.css";

import { useContext } from "react";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import { useSanctum } from "react-sanctum";

import RootBoundary from "./Errors/Boundary";
import { AppLayout } from "./Layouts/AppLayout";
import Privacy from "./Pages/Legal/Privacy";
import Terms from "./Pages/Legal/Terms";
import { Logout, PermissionContext } from "./Providers/Authentication";

function App() {
    const router = createBrowserRouter([
        {
            id: "root",
            path: "/",
            Component: AppLayout,
            errorElement: <RootBoundary />,
            children: [
                {
                    index: true,
                    Component: Index,
                },
                {
                    path: "dashboard",
                    lazy: () => import("./Pages/Dashboard/Dashboard"),
                },
                {
                    path: "notifications",
                    lazy: () => import("./Pages/Notifications/Index"),
                },
                {
                    path: "tracks",
                    children: [
                        {
                            index: true,
                            lazy: () => import("./Pages/Tracks/Index"),
                        },
                        {
                            path: "create",
                            lazy: () => import("./Pages/Tracks/New"),
                        },
                        {
                            path: ":TrackSlug/edit",
                            lazy: () => import("./Pages/Tracks/Edit"),
                        },
                        {
                            path: ":TrackSlug",
                            lazy: () => import("./Pages/Tracks/Show"),
                        },
                        {
                            path: ":TrackSlug/karts",
                            lazy: () => import("./Pages/Tracks/Karts/Index"),
                        },
                        {
                            path: ":TrackSlug/layouts",
                            lazy: () => import("./Pages/Tracks/Layouts/Index"),
                        },
                        {
                            path: ":TrackSlug/:EventSlug",
                            lazy: () => import("./Pages/Events/Show"),
                        },
                        {
                            path: ":TrackSlug/:EventSlug/edit",
                            lazy: () => import("./Pages/Events/Edit"),
                        },
                        {
                            path: ":TrackSlug/:EventSlug/Laps",
                            lazy: () => import("./Pages/Events/Laps/Edit"),
                        },
                        {
                            path: ":TrackSlug/:EventSlug/session/create",
                            lazy: () => import("./Pages/Events/Sessions/New"),
                        },
                        {
                            path: ":TrackSlug/:EventSlug/session/:SessionId/edit",
                            lazy: () => import("./Pages/Events/Sessions/Edit"),
                        },
                        {
                            path: ":TrackSlug/:EventSlug/session/:SessionId/drivers",
                            lazy: () => import("./Pages/Events/Drivers/Manage"),
                        },
                    ],
                },
                {
                    path: "events",
                    children: [
                        {
                            index: true,
                            lazy: () => import("./Pages/Events/Events"),
                        },
                        {
                            path: "create",
                            lazy: () => import("./Pages/Events/New"),
                        },
                        {
                            path: "import",
                            lazy: () => import("./Pages/Events/Import/Import"),
                        },
                    ],
                },

                {
                    path: "restricted",
                    lazy: () => import("./Pages/Restricted/Index"),
                },
                {
                    path: "user/account",
                    lazy: () => import("./Pages/User/Account"),
                },
                {
                    path: "user/profile",
                    lazy: () => import("./Pages/User/Profile"),
                },
                {
                    path: "user/security",
                    lazy: () => import("./Pages/User/Security"),
                },
                {
                    path: "user/authentication",
                    lazy: () => import("./Pages/User/Authentication"),
                },
                {
                    path: "user/notifications",
                    lazy: () => import("./Pages/User/Notifications"),
                },
                {
                    path: "user/invitations",
                    lazy: () => import("./Pages/User/Invitations/Show"),
                },

                // Must be the last children of root!
                {
                    path: ":username/follows",
                    lazy: () => import("./Pages/Profile/Follows"),
                },
                {
                    path: ":username/followers",
                    lazy: () => import("./Pages/Profile/Followers"),
                },
                {
                    path: ":username/:item",
                    lazy: () => import("./Pages/Profile/Single"),
                },
                {
                    path: ":username",
                    lazy: () => import("./Pages/Profile/Show"),
                },
            ],
        },
        {
            id: "admin",
            path: "/admin",
            Component: AppLayout,
            children: [
                {
                    index: true,
                    Component: Admin,
                },
                {
                    path: "dashboard",
                    lazy: () => import("./Pages_Admin/Dashboard"),
                },
                {
                    path: "users",
                    lazy: () => import("./Pages_Admin/Users/Index"),
                },
                {
                    path: "users/:username/activity",
                    lazy: () => import("./Pages_Admin/Users/Activity"),
                },
                {
                    path: "users/:username/events",
                    lazy: () => import("./Pages_Admin/Users/Events"),
                },
                {
                    path: "users/:username/restriction",
                    lazy: () => import("./Pages_Admin/Users/Restriction"),
                },
                {
                    path: "users/:username",
                    lazy: () => import("./Pages_Admin/Users/Show"),
                },
            ],
        },
        {
            path: "login",
            lazy: () => import("./Pages/Authentication/Login"),
        },
        {
            path: "login/two-factor",
            lazy: () => import("./Pages/Authentication/TwoFactorChallenge"),
        },
        {
            path: "logout",
            Component: Logout,
        },
        {
            path: "register",
            lazy: () => import("./Pages/Authentication/Regsiter"),
        },
        {
            path: "verify",
            lazy: () => import("./Pages/Authentication/VerifyEmail"),
        },
        {
            path: "forgot-password",
            lazy: () => import("./Pages/Authentication/ForgotPassword"),
        },
        {
            path: "reset-password",
            lazy: () => import("./Pages/Authentication/ResetPassword"),
        },
        {
            path: "terms-of-service",
            Component: Terms,
        },
        {
            path: "privacy-policy",
            Component: Privacy,
        },
    ]);
    /**
     * TODO:
     * * Admin [started]
     * * Help
     * * Error Handling
     * * Social Login
     */

    return <RouterProvider router={router} />;
}

function Index() {
    const { authenticated } = useSanctum();

    if (authenticated) {
        return <Navigate to={"/dashboard"} replace />;
    }

    return <Navigate to={"/tracks"} replace />;
}

function Admin() {
    const { authenticated } = useSanctum();
    const perms = useContext(PermissionContext);

    if (authenticated && perms.includes("admin.access")) {
        return <Navigate to={"/admin/dashboard"} replace />;
    }

    return <Navigate to={"/"} replace />;
}

export default App;
