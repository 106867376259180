import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { ApiUrl } from "./API_URL";
import { getUA } from "./DeviceType";

const firebaseConfig = {
    apiKey: "AIzaSyC7BnkaDwRyH_tyTJE7rj7ikC3UXzBaymE",
    authDomain: "kartsocial-410815.firebaseapp.com",
    projectId: "kartsocial-410815",
    storageBucket: "kartsocial-410815.appspot.com",
    messagingSenderId: "543930211571",
    appId: "1:543930211571:web:d689a70efdc0c2f7cfab86",
    measurementId: "G-ZXM3NGGNRH",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

let device_token = "";

export const requestForToken = () => {
    return getToken(messaging, {
        vapidKey: process.env.REACT_APP_VITE_PUBLIC_VAPID_KEY,
    })
        .then((currentToken) => {
            if (currentToken) {
                // Perform any other neccessary action with the token
                device_token = currentToken;

                // Send the token to server for storage
                fetch(ApiUrl("notifications/device"), {
                    method: "POST",
                    body: JSON.stringify({
                        token: currentToken,
                        type: getUA(),
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                });
            } else {
                // Show permission request UI
                console.log(
                    `No registration token available. Request permission to generate one.`,
                );

                Notification.requestPermission().then((permission) => {
                    if (permission !== "granted") {
                        console.log("Notification permission not granted!");
                    }
                });
            }
        })
        .catch((error) => {
            if (error.response?.status && error.response?.status !== 422) {
                throw new Response(error.response?.message || "", {
                    status: error.response?.status,
                });
            }
        });
};

export const isCurrentDevice = (token) => {
    if (device_token !== "") {
        return token === device_token;
    } else {
        return getToken(messaging, {
            vapidKey: process.env.REACT_APP_VITE_PUBLIC_VAPID_KEY,
        })
            .then((currentToken) => {
                if (currentToken) {
                    device_token = currentToken;
                }
            })
            .catch((error) => {
                if (error.response.status && error.response.status !== 422) {
                    throw new Response(error.response.message || "", {
                        status: error.response.status,
                    });
                }
            });
    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("payload", payload);
            resolve(payload);
        });
    });
