import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface Props {
    to: string;
    active?: boolean;
}

export default function NavLink({
    active,
    to,
    children,
}: PropsWithChildren<Props>) {
    const classes = active
        ? "text-sm font-medium leading-5 text-brand-700 dark:text-brand-500 flex flex-row items-center gap-2"
        : "text-sm font-medium leading-5 text-gray-400 dark:text-gray-400 flex flex-row items-center gap-2";

    return (
        <Link to={to} className={classes}>
            {children}
        </Link>
    );
}
