import { ClockIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import axios from "axios";
import moment from "moment";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { ApiUrl } from "src/Helpers/API_URL";
import { Notification } from "src/types";

import ProfilePhoto from "../UserProfile/ProfilePhoto";

interface Props {
    notification: Notification;
    date?: Boolean;
}

export default function NotificationItem({
    notification,
    date = true,
}: PropsWithChildren<Props>) {
    const navigate = useNavigate();

    const markAsRead = async (notification: Notification) => {
        if (!notification.read_at) {
            notification.read_at = moment().format("Y-M-D H:m:s");
            await axios
                .put(ApiUrl(`notifications/${notification.id}/read`), {
                    read_at: notification.read_at,
                })
                .then(() => {
                    if (notification.data.url) {
                        return navigate(notification.data.url);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        } else if (notification.data.url) {
            return navigate(notification.data.url);
        }
    };

    return (
        <div
            onClick={() => markAsRead(notification)}
            className="flex items-center gap-4 w-full"
        >
            <ProfilePhoto user={notification.causer} variant="rounded" />
            <div className="flex flex-col w-full gap-1 overflow-hidden">
                <Typography
                    variant="small"
                    color="gray"
                    className={`${notification.read_at ? "" : "font-semibold"}`}
                >
                    {notification.data.title}
                </Typography>
                <Typography
                    variant="small"
                    color="gray"
                    className="font-normal truncate"
                >
                    {notification.data.message}
                </Typography>
                {date && (
                    <Typography className="flex items-center ml-auto gap-1 text-xs font-medium text-blue-gray-500">
                        <ClockIcon className="size-4" />
                        {moment(notification.updated_at).calendar()}
                    </Typography>
                )}
            </div>
        </div>
    );
}
